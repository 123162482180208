import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { UIRouterModule } from '@uirouter/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { EventServiceNg2 } from 'app/entities/event/event.service';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    NgbNavModule,
    FormsModule,
    NgbTooltipModule,
    SharedModule,
    UIRouterModule,
    NgbDropdownModule,
    NgSelectModule, // TODO replace with ngx-select
  ],
  providers: [EventServiceNg2],
  exports: [
    TranslateModule,
    CommonModule,
    NgbNavModule,
    FormsModule,
    NgbTooltipModule,
    SharedModule,
    UIRouterModule,
    NgbDropdownModule,
    NgSelectModule,
  ],
})
export class CoreModule {
  constructor() {
    console.log('Created CoreModule');
  }
}
