import {
  Component,
  ComponentFactoryResolver,
  Directive,
  Input,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Directive({ selector: '[copyToCb]' })
export class CopyToCbDirective {
  @Input('copyToCb') cbText: string;
  @Input('copyToCbDecorate') decorate = true;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private resolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const compFactory = this.resolver.resolveComponentFactory(CopyToCbComponent);
    const component = this.viewContainer.createComponent(compFactory);
    component.instance.cbText = this.cbText;
    component.instance.templateRef = this.templateRef;
    component.instance.decorate = this.decorate;
  }
}

@Component({
  selector: 'copy-to-cb',
  template: `<span
    class="copy-to-cb"
    [ngClass]="{ 'copy-to-cb__decorated': decorate }"
    ngxClipboard
    [cbContent]="cbText"
    (cbOnSuccess)="onCopySuccess()"
    ngbTooltip="Kopioitu"
    tooltipClass="kn-tooltip"
    #tooltip="ngbTooltip"
    triggers="manual"
  >
    <ng-container [ngTemplateOutlet]="templateRef"></ng-container>
  </span>`,
})
export class CopyToCbComponent {
  @Input('copyToCb') cbText: string;
  @Input() templateRef: TemplateRef<any>;
  @Input() decorate = true;
  @ViewChild('tooltip') tooltip: NgbTooltip;

  onCopySuccess() {
    this.tooltip.open();
    setTimeout(() => this.tooltip.close(), 1000);
  }
}
