import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface ICredentials {
  username: string;
  password: string;
  rememberMe: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthServerProviderService {
  constructor(private http: HttpClient) {}

  login(credentials: ICredentials): Promise<boolean> {
    const data =
      'j_username=' +
      encodeURIComponent(credentials.username) +
      '&j_password=' +
      encodeURIComponent(credentials.password) +
      '&remember-me=' +
      credentials.rememberMe +
      '&submit=Login';

    return this.http
      .post('api/authentication', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        observe: 'response',
      })
      .toPromise()
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        if (response.status === 401) {
          return false;
        }
        throw response;
      })
      .catch((response) => {
        console.log(response.status);
        return false;
      });
  }

  logout() {
    // logout from the server
    return this.http
      .post('api/logout', null, { observe: 'response' })
      .toPromise()
      .then((response) => {
        // FIXME
        // delete $localStorage.authenticationToken;
        // to get a new csrf token call the api
        this.http.get('api/account');
        return response;
      });
  }
}

console.log('Registering AuthServerProvider');
// legacyComponents.factory('AuthServerProvider', AuthServerProvider);

AuthServerProvider.$inject = ['$http', '$localStorage'];

function AuthServerProvider($http, $localStorage) {
  var service = {
    getToken: getToken,
    hasValidToken: hasValidToken,
    login: login,
    logout: logout,
  };

  return service;

  function getToken() {
    var token = $localStorage.authenticationToken;
    return token;
  }

  function hasValidToken() {
    var token = this.getToken();
    return !!token;
  }

  function login(credentials) {
    var data =
      'j_username=' +
      encodeURIComponent(credentials.username) +
      '&j_password=' +
      encodeURIComponent(credentials.password) +
      '&remember-me=' +
      credentials.rememberMe +
      '&submit=Login';

    return $http
      .post('api/authentication', data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .success(function (response) {
        return response;
      });
  }

  function logout() {
    // logout from the server
    $http.post('api/logout').success(function (response) {
      delete $localStorage.authenticationToken;
      // to get a new csrf token call the api
      $http.get('api/account');
      return response;
    });
  }
}
