import { TranslateService } from '@ngx-translate/core';
import { interval, Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AlertService {
  toast = false;
  alertId = 0; // unique id for each alert. Starts from 0.
  alerts = [];
  timeout = 5000; // default timeout
  alertsSubject = new Subject();

  constructor(private readonly translateService: TranslateService) {}

  isToast() {
    return this.toast;
  }

  clear() {
    this.alerts = [];
    this.alertsSubject.next(this.alerts);
  }

  get() {
    return this.alertsSubject;
  }

  success(msg, params, position = 0) {
    return this.add({
      type: 'success',
      msg: msg,
      params: params,
      timeout: this.timeout,
      toast: this.toast,
      position: position,
    });
  }

  error(msg, params, position) {
    return this.add({
      type: 'danger',
      msg: msg,
      params: params,
      timeout: this.timeout,
      toast: this.toast,
      position: position,
    });
  }

  warning(msg, params, position) {
    return this.add({
      type: 'warning',
      msg: msg,
      params: params,
      timeout: this.timeout,
      toast: this.toast,
      position: position,
    });
  }

  info(msg, params = undefined, position = undefined) {
    return this.add({
      type: 'info',
      msg: msg,
      params: params,
      timeout: this.timeout,
      toast: this.toast,
      position: position,
    });
  }

  factory(alertOptions) {
    const alert = {
      type: alertOptions.type,
      msg: alertOptions.msg,
      id: alertOptions.alertId,
      timeout: alertOptions.timeout,
      toast: alertOptions.toast,
      position: alertOptions.position ? alertOptions.position : 'top right',
      scoped: alertOptions.scoped,
      close: (alerts) => {
        return this.closeAlert(alertOptions.alertId, alerts);
      },
    };
    if (!alert.scoped) {
      this.alerts.push(alert);
      this.alertsSubject.next(this.alerts);
    }
    return alert;
  }

  add(alertOptions, extAlerts = undefined) {
    alertOptions.alertId = this.alertId++;
    alertOptions.msg = this.translateService.instant(alertOptions.msg, alertOptions.params);
    const alert = this.factory(alertOptions);
    if (alertOptions.timeout && alertOptions.timeout > 0) {
      interval(alertOptions.timeout)
        .pipe(take(1))
        .toPromise()
        .then(() => this.closeAlert(alertOptions.alertId, extAlerts));
    }
    return alert;
  }

  closeAlert(id, extAlerts) {
    const thisAlerts = extAlerts ? extAlerts : this.alerts;
    return this.closeAlertByIndex(thisAlerts.map((e) => e.id).indexOf(id), thisAlerts);
  }

  closeAlertByIndex(index, thisAlerts) {
    const removedAlerts = thisAlerts.splice(index, 1);
    this.alertsSubject.next(this.alerts);
    return removedAlerts;
  }
}
