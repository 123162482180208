<section *ngIf="showCookieWindow" class="cookie">
  <div class="container">
    <div class="row cookie__row">
      <div class="col-lg-10 col-md-12">
        <h4 class="cookie__heading">Tämä sivusto käyttää evästeitä</h4>
        <p class="cookie__text"></p>
      </div>

      <div class="col-lg-2 col-md-12">
        <button (click)="onCookiesAccepted()" class="btn cookie__button">Hyväksy</button>
      </div>
    </div>
  </div>
</section>
