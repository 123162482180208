import './language.constants';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

const LANGUAGES = ['fi', 'en'];

@Injectable()
export class JhiLanguageService {
  constructor(private $translate: TranslateService) {}

  getAll() {
    return Promise.resolve(LANGUAGES);
  }

  getCurrent() {
    const language = this.$translate.currentLang;
    return Promise.resolve(language);
  }
}
