import { Injectable } from '@angular/core';

export interface ToastInfo {
  body: string;
  delay?: number;
  params?: Record<string, number | string>;
  type: 'info' | 'error' | 'warning';
}

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: ToastInfo[] = [];

  show(body: string, params: Record<string, number | string> = {}, delay: number = 5000) {
    this.toasts.push({ body, delay, params, type: 'info' });
  }

  showError(body: string, params: Record<string, number | string> = {}, delay: number = 5000) {
    this.toasts.push({ body, delay, params, type: 'error' });
  }

  showWarning(body: string, params: Record<string, number | string> = {}, delay: number = 5000) {
    this.toasts.push({ body, delay, params, type: 'warning' });
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }
}
