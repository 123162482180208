import { Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgIf, NgIfContext } from '@angular/common';
import { PermissionService } from 'app/services/auth/permission.service';
import { ContextService } from 'app/services/context.service';
import { PrincipalService } from 'app/services/auth/principal.service';
import { Observable, Subscription } from 'rxjs';
import { AbstractShowHideDirective } from 'app/services/auth/abstract-show-hide.directive';
import { undefined } from 'zod';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[isEventAdmin]',
})
export class IsEventAdminDirective extends AbstractShowHideDirective {
  constructor(
    ctx: ContextService,
    principalService: PrincipalService,
    permissionService: PermissionService,
    _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext<boolean>>
  ) {
    super(
      _viewContainer,
      templateRef,
      principalService.getCurrentIdentity().pipe(
        map(() => {
          const event = ctx.get('event');
          return permissionService.isEventAdmin(event) || permissionService.isFedUser();
        })
      )
    );
  }
}
