<section class="promo-block">
  <ngb-carousel
    class="promo-block__carousel"
    [interval]="5000"
    [activeId]="active + ''"
    [pauseOnFocus]="false"
    [showNavigationArrows]="false"
  >
    <ng-template ngbSlide *ngFor="let slide of slides">
      <div class="promo-block__inner" [style]="{'background-image': 'url(' + slide.image + ')'}"></div>
    </ng-template>
  </ngb-carousel>
</section>
