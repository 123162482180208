<div>
  <div *ngIf="definition.large" class="d-none d-md-flex justify-content-center">
    <div>
      <img
        alt=""
        [src]="definition.large.url"
        class="advertisement__image advertisement__image--large"
        [rwdImgMap]="definition.large.url"
        usemap="#adImageMapLarge"
        [map]="mapLarge"
      />

      <map name="adImageMapLarge" #mapLarge>
        <area
          *ngFor="let areaDef of definition.large.clickMap"
          [alt]="areaDef.normalizedUrl"
          [shape]="areaDef.shapeType"
          [coords]="areaDef.shapeCoords"
          [href]="areaDef.normalizedUrl"
          target="_blank"
        />
      </map>
    </div>
  </div>
  <div *ngIf="definition.small" class="d-md-none d-sm-flex justify-content-center">
    <div>
      <img
        alt=""
        [src]="definition.small.url"
        class="advertisement__image advertisement__image--small"
        [rwdImgMap]="definition.small.url"
        usemap="#adImageMapSmall"
        [map]="mapSmall"
      />

      <map name="adImageMapSmall" #mapSmall>
        <area
          *ngFor="let areaDef of definition.small.clickMap"
          [alt]="areaDef.normalizedUrl"
          [shape]="areaDef.shapeType"
          [coords]="areaDef.shapeCoords"
          [href]="areaDef.normalizedUrl"
          target="_blank"
        />
      </map>
    </div>
  </div>
</div>
