import { NgModule } from '@angular/core';
import { ParticipationTypeLabelComponent } from 'app/entities/event/summary/participation-type-label.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RgApparatusIconComponent } from 'app/components/event/rg-apparatus-icon.component';
import { CategoryNameComponent } from 'app/entities/event/category-name.directive';
import { EventDateComponent } from 'app/components/event/event-date.component';
import { CoreModule } from 'app/core/core.module';
import { SlEntryCategoryNameComponent } from 'app/entities/event/start-list/sl-entry-category-name.component';
import { StartListEntryProgramLabelComponent } from 'app/entities/event/start-list/start-list-entry-program-label.component';
import { CountryFlagComponent } from 'app/components/event/country-flag.component';

@NgModule({
  imports: [NgbTooltipModule, CoreModule],
  declarations: [
    ParticipationTypeLabelComponent,
    RgApparatusIconComponent,
    CategoryNameComponent,
    EventDateComponent,
    SlEntryCategoryNameComponent,
    StartListEntryProgramLabelComponent,
    CountryFlagComponent,
  ],
  exports: [
    ParticipationTypeLabelComponent,
    RgApparatusIconComponent,
    CategoryNameComponent,
    EventDateComponent,
    SlEntryCategoryNameComponent,
    StartListEntryProgramLabelComponent,
    CountryFlagComponent,
  ],
})
export class EventSharedComponentsModule {}
