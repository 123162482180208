import { Directive, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgIf, NgIfContext } from '@angular/common';
import { PermissionService } from 'app/services/auth/permission.service';
import { ContextService } from 'app/services/context.service';
import { PrincipalService } from 'app/services/auth/principal.service';
import { Observable, Subscription } from 'rxjs';

@Directive()
export abstract class AbstractShowHideDirective implements OnDestroy {
  private ngIf: NgIf;
  private subscription: Subscription;

  protected constructor(
    _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext<boolean>>,
    condition: Observable<boolean>
  ) {
    this.ngIf = new NgIf(_viewContainer, templateRef);
    this.subscription = condition.subscribe({
      next: (value) => (this.ngIf.ngIf = value),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
