import { NgModule } from '@angular/core';
import { CoreModule } from 'app/core/core.module';
import { AdvertisementComponent } from './advertisement.component';
import { AdBannerEditorComponent } from './ad-banner-editor.component';
import { AdBannerMultiEditorComponent } from './ad-banner-multi-editor.component';
import { SelectionAreaComponent } from './selection-area.component';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { RwdImgMapDirective } from 'app/components/util/rwd-img-map.directive';

@NgModule({
  imports: [CoreModule, NgbProgressbarModule],
  declarations: [
    AdBannerEditorComponent,
    AdBannerMultiEditorComponent,
    SelectionAreaComponent,
    AdvertisementComponent,
    RwdImgMapDirective,
  ],
  exports: [AdvertisementComponent, AdBannerMultiEditorComponent],
})
export class BannersModule {}
