import { Component, Input } from '@angular/core';

@Component({
  selector: 'event-date',
  template: `<span class="value" *ngIf="!isMultiDay()">{{ event.startDate | date: 'mediumDate' }}</span
    ><!--
  --><span class="value" *ngIf="isMultiDay()">
      {{ event.startDate | date: 'mediumDate' }} - {{ event.endDate | date: 'mediumDate' }}
    </span>`,
})
export class EventDateComponent {
  @Input() event: { startDate?: Date; endDate?: Date };

  isMultiDay() {
    if (this.event.startDate && this.event.endDate) {
      return this.event.startDate.getTime() !== this.event.endDate.getTime();
    }
  }
}
