import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AppReloadService {
  private reloadRequested = new Subject<void>();

  showReloadPrompt() {
    this.reloadRequested.next();
  }

  getReloadRequest() {
    return this.reloadRequested.asObservable();
  }
}
