import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ResetRequestComponent } from 'app/account/reset/request/reset.request.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ResetFinishComponent } from 'app/account/reset/finish/reset.finish.component';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [ResetRequestComponent, ResetFinishComponent],
  exports: [ResetRequestComponent],
})
export class PasswordResetModule {}
