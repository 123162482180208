import { Component, OnDestroy } from '@angular/core';
import { AppReloadService } from 'app/services/app-reload.service';
import { Subscription } from 'rxjs';
import { vi } from 'date-fns/locale';

@Component({
  selector: 'app-reload-toast',
  template: `
    <div *ngIf="visible" class="position-fixed top-0, left-0 w-100 toast-container">
      <ngb-toast [autohide]="false">
        <div class="text-center">
          <div [translate]="'global.messages.info.appUpdated'">Updated</div>
          <div>
            <button class="btn btn-flat" (click)="reloadPage()">OK</button>
          </div>
        </div>
      </ngb-toast>
    </div>
  `,
  styles: [
    `
      .toast-container {
        z-index: 10000;
      }

      .toast {
        max-width: 100%;
        width: 100%;
        background-color: #f83c3c;
        color: white;
        min-height: 70px;
        padding: 10px;
      }
    `,
  ],
})
export class AppReloadToastComponent implements OnDestroy {
  visible = false;
  private subscription: Subscription;

  constructor(appReloadService: AppReloadService) {
    this.subscription = appReloadService.getReloadRequest().subscribe(() => {
      this.visible = true;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  reloadPage() {
    window.location.reload();
    this.visible = false;
  }

  protected readonly vi = vi;
}
