import { Component, Input } from '@angular/core';

@Component({
  selector: 'ellipsis, [ellipsis]',
  template: `
    <span class="text-overflow-dynamic-container" [ngbTooltip]="tooltipText" tooltipClass="kn-tooltip">
      <span class="text-overflow-dynamic-ellipsis"><ng-content></ng-content></span>
    </span>
  `,
})
export class EllipsisDirective {
  @Input() tooltipText;

  constructor() {}
}
