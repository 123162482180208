import { z } from 'zod';

const RoundSummarySchema = z.object({
  categoryId: z.number(),
  roundId: z.number(),
  categoryName: z.string(),
  roundName: z.string(),
});

const StartListPanelSummarySchema = z.object({
  panelId: z.number(),
  startListName: z.string(),
  blockName: z.string(),
  panelName: z.string(),
  rounds: z.array(RoundSummarySchema),
  judgePanelId: z.number().optional().nullable(),
});

const StartListInfoSchema = z.object({
  id: z.number(),
  name: z.string(),
  public: z.boolean(),
  panels: z.array(StartListPanelSummarySchema),
  startTime: z.string().pipe(z.coerce.date()).optional().nullable(),
});

export const StartListInfoArraySchema = z.array(StartListInfoSchema);

export type StartListInfo = z.infer<typeof StartListInfoSchema>;
