import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

const JSON_METHODS = ['post', 'put'];

export const SKIP_CONTENT_TYPE_INTERCEPTOR = '_$SKIP_CONTENT_TYPE_INTERCEPTOR$_';

export class ContentTypeInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skip = req.params.has(SKIP_CONTENT_TYPE_INTERCEPTOR);
    if (!skip && JSON_METHODS.includes(req.method.toLowerCase()) && req.url !== 'api/authentication') {
      const clonedRequest = req.clone({ setHeaders: { 'Content-Type': 'application/json' } });
      return next.handle(clonedRequest);
    }
    if (skip) {
      const clonedRequest = req.clone({ params: req.params.delete(SKIP_CONTENT_TYPE_INTERCEPTOR) });
      return next.handle(clonedRequest);
    }
    return next.handle(req);
  }
}
