<div>
  <ul
    class="event-list _future-events"
    infiniteScroll
    (scrolled)="onScroll()"
    [infiniteScrollDisabled]="loader?.loading"
    [infiniteScrollDistance]="1"
  >
    <li class="row event-item" *ngFor="let event of events">
      <event-card [event]="event" [buttons]="{registerJudges: true}" class="container-fluid"> </event-card>
    </li>
  </ul>

  <div *ngIf="loader?.loading" class="d-flex justify-content-center">
    <svg-icon icon="spinner-large"></svg-icon>
  </div>
  <div class="text-center" *ngIf="events.length == 0 && !loader?.loading">
    {{noEventsText}}
  </div>
</div>
