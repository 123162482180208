import { formatISO, lightFormat, parseISO } from 'date-fns';
import { format } from 'date-fns-tz';

export const FIXED_MEDIUM_DATE_FORMAT = 'd.M.yyyy';

DateUtils.$inject = ['$filter', '$locale'];

function DateUtils($filter, $locale) {
  var service = {
    convertDateTimeFromServer: convertDateTimeFromServer,
    convertLocalDateFromServer: convertLocalDateFromServer,
    convertLocalDateToServer: convertLocalDateToServer,
    dateformat: dateformat,
    updateDateSecondsWith: updateDateSecondsWith,
    updateDateMinutesWith: updateDateMinutesWith,
    updateDateHoursWith: updateDateHoursWith,
    isBetweenDays: isBetweenDays,
    diffSec: diffSec,
    isValid: isValidDate,
    daysInRange: daysInRange,
    setDay: setDay,
    FIXED_MEDIUM_DATE_FORMAT: FIXED_MEDIUM_DATE_FORMAT,
  };

  return service;

  function dateformat() {
    return 'yyyy-MM-dd';
  }

  /**
   * Returns the updated `Date` object with seconds without source date mutation.
   *
   * @param {Date} date `Date` object takes as source date time.
   * @param {number} seconds Adding to the source date time.
   * @returns {Date} Without source date mutation.
   */
  function updateDateSecondsWith(date, seconds) {
    var newDate = new Date(date);
    newDate.setSeconds(newDate.getSeconds() + seconds);

    return newDate;
  }

  /**
   * Returns the updated `Date` object with minutes without source date mutation.
   *
   * @param {Date} date `Date` object takes as source date time.
   * @param {number} minutes Adding to the source date time.
   * @returns {Date} Without source date mutation.
   */
  function updateDateMinutesWith(date, minutes) {
    var newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + minutes);

    return newDate;
  }

  /**
   * Returns the updated `Date` object with hours without source date mutation.
   *
   * @param {Date} date `Date` object takes as source date time.
   * @param {number} hours Adding to the source date time.
   * @returns {Date} Without source date mutation.
   */
  function updateDateHoursWith(date, hours) {
    var newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);

    return newDate;
  }
}

export function setDay(target, daySource) {
  target.setFullYear(daySource.getFullYear());
  target.setMonth(daySource.getMonth());
  target.setDate(daySource.getDate());
}

export function daysInRange(startDate, endDate) {
  let day = new Date(startDate.getTime());
  const days = [];
  while (day <= endDate) {
    days.push(day);
    day = new Date(day.getTime());
    day.setDate(day.getDate() + 1);
  }
  return days;
}

export function isValidDate(date: any): boolean {
  return date instanceof Date && !isNaN(date.getTime());
}

export function formatFixedFormatMediumDate(date) {
  if (date) {
    return lightFormat(date, FIXED_MEDIUM_DATE_FORMAT);
  }
}

export function diffSec(date1: Date, date2: Date) {
  return Math.floor((date1.getTime() - date2.getTime()) / 1000);
}

export function convertDateTimeFromServer(date: string | Date): Date {
  return !date ? null : typeof date === 'string' ? parseISO(date) : date;
}

export function convertLocalDateFromServer(date: string | Date): Date {
  if (date && typeof date === 'string') {
    const [year, month, day] = date.split('-');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  }
  return date as Date;
}

export function convertLocalDateToServer(date: Date): string {
  if (date) {
    return formatISO(date, { representation: 'date' });
  } else {
    return null;
  }
}

export function convertDateTimeToIso(date: Date): string {
  return convertDateTimeToServer(date, true);
}

export function convertDateTimeToServer(date: Date, useIsoDateTimeFormat: boolean = false): string {
  if (date) {
    return useIsoDateTimeFormat
      ? formatISO(date, { representation: 'complete' })
      : format(date, `yyyy-MM-dd'T'HH:mm:ss.SSSXXX`);
  } else {
    return null;
  }
}

export function isBetweenDays(testDate: Date, startDate: Date, endDate: Date): boolean {
  const t1 = startDate.getTime();
  const endTime = new Date(endDate);
  endTime.setDate(endTime.getDate() + 1);
  const t2 = endTime.getTime() - 1000;
  const t = testDate.getTime();
  return t >= t1 && t <= t2;
}

export function endOfDay(date: Date): Date {
  const endOfDay = new Date(date.getTime());
  endOfDay.setHours(23, 59, 59, 999);
  return endOfDay;
}
