import { NgModule } from '@angular/core';
import { CoreModule } from 'app/core/core.module';
import { EventCardComponent } from 'app/components/event/event-card.component';
import { EventLabelComponent } from 'app/entities/event/event-label.component';
import { SharedModule } from 'app/shared/shared.module';
import { EventSharedComponentsModule } from 'app/features/event-components/event-shared-components.module';
import { CountryAndClubLabelComponent } from 'app/components/event/country-and-club-label.component';

@NgModule({
  imports: [CoreModule, SharedModule, EventSharedComponentsModule],
  declarations: [EventCardComponent, EventLabelComponent, CountryAndClubLabelComponent],
  exports: [EventCardComponent, EventLabelComponent, CountryAndClubLabelComponent],
})
export class EventsModule {}
