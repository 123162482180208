import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { toHttpParams } from 'app/services/utils.service';
import { SKIP_CACHE_BUSTER_INTERCEPTOR } from 'app/blocks/interceptor/cache-buster.interceptor';

@Injectable({ providedIn: 'root' })
export class AppSettingsService {
  constructor(private http: HttpClient) {}

  get(forceFetch = false): Promise<any> {
    if (forceFetch) {
      return this.http.get('/api/public/app-settings').toPromise();
    }
    const params = toHttpParams({ [SKIP_CACHE_BUSTER_INTERCEPTOR]: true });
    return this.http.get('/api/public/app-settings', { params }).toPromise();
  }

  save(data): Promise<any> {
    return this.http.put('/api/app-settings', data).toPromise();
  }
}

AppSettings.$inject = ['$http'];

function AppSettings($http) {
  var settings;
  var promise;
  var promiseResolve;
  var promiseReject;

  $http({ mehtod: 'GET', url: '/api/public/app-settings' }).then(
    function (response) {
      settings = response.data;
      if (promiseResolve) {
        promiseResolve({ ...settings });
      }
    },
    function (response) {
      if (promiseReject) {
        promiseReject(response);
      }
    }
  );

  function getSettings() {
    if (settings) {
      return new Promise(function (resolve) {
        resolve({ ...settings });
      });
    } else {
      if (!promise) {
        promise = new Promise(function (resolve, reject) {
          promiseResolve = resolve;
          promiseReject = reject;
        });
      }
      return promise;
    }
  }

  function saveSettings(data) {
    return $http.put('/api/app-settings', data).then(function () {
      settings = { ...data };
    });
  }

  return {
    get: getSettings,
    save: saveSettings,
  };
}
