import { PrincipalService } from 'app/services/auth/principal.service';
import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { Role } from 'app/services/auth/role.constant';
import { Subscription } from 'rxjs';

@Directive()
export abstract class AbstractAuthenticationDirective implements OnDestroy {
  protected subscription: Subscription;

  protected constructor(protected element: ElementRef, protected principalService: PrincipalService) {
    const self = this;
    this.subscription = principalService.getCurrentIdentity().subscribe({
      next() {
        if (self.elementVisible()) {
          element.nativeElement.classList.remove('hidden');
        } else {
          element.nativeElement.classList.add('hidden');
        }
      },
    });
  }

  abstract elementVisible(): boolean;

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
