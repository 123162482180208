import { ResetRequestComponent } from 'app/account/reset/request/reset.request.component';
import { Ng2StateDeclaration } from '@uirouter/angular';

export const resetRequestState: Ng2StateDeclaration = {
  name: 'app.account.requestReset',
  url: '/reset/request',
  data: {
    authorities: [],
  },
  views: {
    'content@': {
      component: ResetRequestComponent,
    },
  },
  resolve: [
    {
      token: '$pageTitle',
      resolveFn: () => 'user.messages.pwResetTitle',
    },
  ],
};
