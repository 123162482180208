import { Component, OnDestroy } from '@angular/core';
import { PrincipalService } from 'app/services/auth/principal.service';
import { Subscription } from 'rxjs';
import { hideChatWidget, showChatWidget } from 'app/components/chat/tawk';

declare var Tawk_API: any;
declare var TawkAPILoaded: boolean;

@Component({
  selector: 'tawk-chat-widget',
  template: '',
})
export class TawkChatWidgetComponent implements OnDestroy {
  subscription: Subscription;

  constructor(principalService: PrincipalService) {
    const subscribeToCurrentUser = () => {
      this.subscription = principalService.getCurrentIdentity().subscribe((user) => {
        this.defineWidgetVisibility(user);
      });
    };
    if (TawkAPILoaded) {
      subscribeToCurrentUser();
    } else {
      Tawk_API.onLoad = () => {
        subscribeToCurrentUser();
      };
      Tawk_API.onLoad2 = () => {
        subscribeToCurrentUser();
      };
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  private defineWidgetVisibility(user) {
    if (user) {
      try {
        Tawk_API.setAttributes(
          {
            name: user.firstName + ' ' + user.lastName + '(' + user.organization.name + ')',
            email: user.email || 'no-email@no-email.com',
            hash: user.tawkHash,
          },
          (error) => {
            if (error) {
              console.error('Tawk Error: ', error);
            }
          }
        );
      } finally {
        showChatWidget();
      }
    } else {
      hideChatWidget();
    }
  }
}
