import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: 'footer.html',
})
export class FooterComponent {
  year: number;
  constructor() {
    this.year = new Date().getFullYear();
  }
}
