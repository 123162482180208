<span ngbDropdown *ngIf="current">
  <a class="dropdown-toggle lang-toggle" id="simple-dropdown" ngbDropdownToggle>
    <div class="d-flex">
      <div>
        <country-flag [countryCode]="current"></country-flag>
      </div>
      <div class="ml-3">{{current}}</div>
    </div>
  </a>
  <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="simple-dropdown">
    <li
      ngbDropdownItem
      class="dropdown-item pl-2 pr-2"
      *ngFor="let language of languages"
      (click)="changeLanguage(language)"
    >
      <div class="d-flex">
        <div>
          <country-flag [countryCode]="language"></country-flag>
        </div>
        <!--                FIXME <div class="ml-3">{{language | findLanguageFromKey}}</div>-->
        <div class="ml-3">{{language}}</div>
      </div>
    </li>
  </ul>
</span>
