import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractEventLoader } from 'app/entities/event/event.service';
import { Event } from 'app/entities/event/event.types';

@Component({
  selector: 'event-list',
  templateUrl: 'event-list.html',
})
export class EventListComponent {
  @Input() events: Event[];
  @Input() loader: AbstractEventLoader;
  @Input() noEventsText: string;
  @Output() loadMoreData = new EventEmitter();

  onScroll() {
    this.loadMoreData.emit();
  }
}
