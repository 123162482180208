import {
  EventSearchParameters,
  EventServiceNg2,
  FutureEventLoader,
  PastEventLoader,
} from '../entities/event/event.service';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from 'app/services/context.service';
import { SportService } from 'app/services/sport/sport.service';
import { UtilsService } from 'app/services/utils.service';
import { Event } from 'app/entities/event/event.types';

class EventSearchComponentState {
  queryString: string;
  sportIds: string[];
  loadedFuturePage: number;
  loadedPastPage: number;
  timestamp: Date;
  selectedTabIndex: number;
  scrollPosition: number;

  constructor(
    queryString: string,
    sportIds: string[],
    loadedFuturePage: number,
    loadedPastPage: number,
    selectedTabIndex: number,
    scrollPosition: number
  ) {
    this.queryString = queryString;
    this.sportIds = sportIds;
    this.loadedFuturePage = loadedFuturePage;
    this.loadedPastPage = loadedPastPage;
    this.selectedTabIndex = selectedTabIndex;
    this.scrollPosition = scrollPosition;
    this.timestamp = new Date();
  }
}

const XS_MAX_WIDTH: number = 576;
const STATE_VALITITY_TIME_MSEC = 10 * 60 * 1000; // 10 min

@Component({
  templateUrl: 'eventsearch.html',
  styles: [
    `
      .nav-tabs {
        .nav-item {
          a.nav-link.live-events-tab-header.active {
            color: var(--red);
          }
        }
      }
      .event-search-nav-tabs {
        margin-bottom: 6px;
      }
      .event-search-input {
        padding-right: 40px;
      }
    `,
  ],
})
export class EventSearchComponent implements OnInit, OnDestroy {
  liveEvents: Event[];
  futureEvents: Event[];
  pastEvents: Event[];
  futureLoader: FutureEventLoader;
  pastLoader: PastEventLoader;
  loadingLive: boolean;
  searchString: string;
  doneTypingInterval: number;
  selectedTabIndex: number;
  filters: any;
  availableSportTypes: any;
  counter: number;
  showScrollUpAfter: number;
  scrollUpTarget: any;
  typingTimer: any;
  availableSportIds: string[];
  lastScrollPosition: number;
  initialized = false;

  constructor(
    private eventService: EventServiceNg2,
    private contextService: ContextService,
    private sportService: SportService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.liveEvents = [];
    this.futureEvents = [];
    this.pastEvents = [];

    this.futureLoader = new FutureEventLoader(this.eventService, this.futureEvents);
    this.pastLoader = new PastEventLoader(this.eventService, this.pastEvents);

    this.loadingLive = false;

    this.searchString = '';
    this.doneTypingInterval = 500;
    this.selectedTabIndex = 0;

    this.filters = this.emptyFilters();
    this.availableSportTypes = [];

    this.counter = 0;

    this.loadFilters();

    this.loadLiveEvents();
    this.loadSportTypes();
    const savedState: EventSearchComponentState = this.contextService.get('eventSearchComponentState');

    if (savedState && new Date().getTime() - savedState.timestamp.getTime() <= STATE_VALITITY_TIME_MSEC) {
      this.searchString = savedState.queryString;
      Promise.all([
        this.futureLoader.loadFromState(savedState.queryString, savedState.sportIds, savedState.loadedFuturePage),
        this.pastLoader.loadFromState(savedState.queryString, savedState.sportIds, savedState.loadedPastPage),
      ])
        .then(() => setTimeout(() => this.scrollVertically(savedState.scrollPosition), 50))
        .finally(() => (this.initialized = true));
      this.selectedTabIndex = savedState.selectedTabIndex;
      this.scrollVertically(savedState.scrollPosition);
    } else {
      this.futureLoader
        .load(new EventSearchParameters(null, this.selectedSportIds()))
        .then(() => this.updateTabSelection());
      this.pastLoader
        .load(new EventSearchParameters(null, this.selectedSportIds()))
        .then(() => this.updateTabSelection());
      this.initialized = true;
    }

    // $scope.$on('$destroy', saveComponentState);

    this.showScrollUpAfter = 600;
    this.scrollUpTarget = 500;
    if (window.innerWidth <= XS_MAX_WIDTH) {
      this.showScrollUpAfter = 450;
      this.scrollUpTarget = 200;
    }
  }

  ngOnDestroy() {
    this.saveComponentState();
  }

  @HostListener('window:scroll')
  onScroll() {
    this.lastScrollPosition = window.scrollY;
  }

  scrollVertically(yPosition) {
    window.scroll({ left: 0, top: yPosition, behavior: 'smooth' });
  }

  loadMoreFutureEvents() {
    return this.futureLoader.loadNextPage();
  }

  loadMorePastEvents() {
    return this.pastLoader.loadNextPage();
  }

  saveFilters() {
    const searchFilters = JSON.stringify(this.filters);
    localStorage.setItem('searchFilters', searchFilters);
  }

  selectedSportIds() {
    return Object.keys(this.filters.sportIds).filter((sportId) => {
      return this.filters.sportIds[sportId];
    });
  }

  emptyFilters() {
    return {
      meta: {
        version: 1,
      },
      startDate: '',
      endDate: '',
      sportIds: {},
    };
  }

  loadFilters() {
    const searchFilters = JSON.parse(localStorage.getItem('searchFilters'));
    this.filters = this.emptyFilters();

    if (searchFilters && searchFilters.meta) {
      this.filters = searchFilters;
      // this.setCounter(searchFilters);
    }
  }

  loadSportTypes() {
    Promise.resolve(this.sportService.types()).then((res) => {
      const sportIds = Object.values(res)
        .filter((value: any) => {
          return value.id !== undefined;
        })
        .map((sport: any) => {
          return sport.id;
        });
      this.availableSportIds = this.utilsService.sortByLocalizedStrings(sportIds, (id) => {
        return 'sport.' + id;
      });
    });
  }

  onSelectedSportsChange() {
    this.search();
    this.saveFilters();
  }

  saveComponentState() {
    const previousStateInfo = this.contextService.get('previousStateInfo');
    this.contextService.set(
      'eventSearchComponentState',
      new EventSearchComponentState(
        this.searchString,
        this.selectedSportIds(),
        this.futureLoader.loadedPage,
        this.pastLoader.loadedPage,
        this.selectedTabIndex,
        previousStateInfo?.scrollY ?? this.lastScrollPosition
      )
    );
  }

  onSearchChange() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => this.search(), this.doneTypingInterval);
  }

  isRegistrationOpen(event) {
    const now = new Date();
    return now < event.registrationEndDate2 && now >= event.registrationStartDate;
  }

  isRegistrationEnded(event) {
    const now = new Date();
    return now > event.registrationEndDate2;
  }

  loadLiveEvents(clearExisting: boolean = false) {
    if (this.loadingLive) return;
    if (clearExisting) {
      this.liveEvents = [];
    }
    this.loadingLive = true;
    this.eventService
      .getLive()
      .then((events) => {
        events.forEach((e) => this.liveEvents.push(e));
        this.updateTabSelection();
      })
      .catch((error) => this.onError(error))
      .finally(() => (this.loadingLive = false));
  }

  search() {
    if (!this.initialized) {
      return;
    }
    this.futureLoader.load(new EventSearchParameters(this.searchString, this.selectedSportIds()));
    this.pastLoader.load(new EventSearchParameters(this.searchString, this.selectedSportIds()));
  }

  updateTabSelection() {
    if (!this.futureLoader.loading && this.loadingLive === false && !this.pastLoader.loading) {
      if (this.selectedTabIndex === 0 && this.liveEvents.length === 0) {
        this.selectedTabIndex = 1;
      }
    }
  }

  onError(error) {
    // AlertService.error(error.data.message);
  }
}
