import './auth.session.service';
import { AuthServerProviderService } from 'app/services/auth/auth.session.service';
import { Injectable } from '@angular/core';
import { PrincipalService } from 'app/services/auth/principal.service';
import { PasswordResetInitService } from 'app/services/auth/password-reset-init.service';
import { PasswordResetFinishService } from 'app/services/auth/password-reset-finish.service';
import { StateService } from '@uirouter/core';
import { ContextService } from 'app/services/context.service';
import { LoginServiceNg2 } from 'app/components/login/login.service';

const PREV_STATE = 'PREV_STATE';

export type FinishPwdResetRequest = {
  key: string;
  newPassword: string;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private authServerProvider: AuthServerProviderService,
    private principalService: PrincipalService,
    private pwdResetInitService: PasswordResetInitService,
    private pwdResetFinishService: PasswordResetFinishService,
    private stateService: StateService,
    private ctxService: ContextService,
    private loginService: LoginServiceNg2
  ) {}

  login(username: string, password: string): Promise<boolean> {
    const loginThen = (success) => {
      if (success) {
        this.principalService.identity(true).then((account) => {
          // After the login the language will be changed to
          // the language selected by the user during his registration
          if (account !== null) {
            // FIXME
            // tmhDynamicLocale.set(account.langKey);
            // $translate.use(account.langKey).then(function () {
            //   $translate.refresh();
            // });
          }
        });
        return true;
      } else {
        this.logout();
        return false;
      }
    };

    return this.authServerProvider.login({ username, password, rememberMe: true }).then(loginThen);
  }

  async logout() {
    const isInRetrictedState = this.stateService.current.data?.authorities?.length > 0;
    await this.authServerProvider.logout();
    //TODO this increases coupling
    this.ctxService.clear('userOrganization');
    if (isInRetrictedState) {
      this.stateService.go('app.home');
    }
    this.principalService.authenticate(null);
  }

  storePreviousState(stateName, stateParams) {
    sessionStorage.setItem(PREV_STATE, JSON.stringify({ name: stateName, params: stateParams }));
  }

  getPreviousState() {
    const value = sessionStorage.getItem(PREV_STATE);
    return value ? JSON.parse(value) : undefined;
  }

  resetPreviousState() {
    sessionStorage.removeItem(PREV_STATE);
  }

  resetPasswordInit(login) {
    return this.pwdResetInitService.save(login);
  }

  resetPasswordFinish(keyAndPassword: FinishPwdResetRequest): Promise<any> {
    return this.pwdResetFinishService.finishReset(keyAndPassword);
  }

  async authorize(force: boolean = false) {
    await this.principalService.identity(force);

    const isAuthenticated = this.principalService.isAuthenticated();

    // an authenticated user can't access to login and register pages
    const toState = this.ctxService.get('toState');
    if (
      isAuthenticated &&
      toState &&
      toState.parent === 'account' &&
      (toState.name === 'login' || toState.name === 'register')
    ) {
      this.stateService.go('home');
    }

    // recover and clear previousState after external login redirect (e.g. oauth2)
    const fromState = this.ctxService.get('fromState');
    if (isAuthenticated && fromState && !fromState.name && this.getPreviousState()) {
      const previousState = this.getPreviousState();
      this.resetPreviousState();
      this.stateService.go(previousState.name, previousState.params);
    }

    if (toState?.data?.authorities?.length > 0 && !this.principalService.hasAnyAuthority(toState?.data?.authorities)) {
      if (isAuthenticated) {
        // user is signed in but not authorized for desired state
        this.stateService.go('accessdenied');
      } else {
        // user is not authenticated. stow the state they wanted before you
        // send them to the login service, so you can return them when you're done
        this.storePreviousState(toState.name, this.ctxService.get('toStateParams'));

        // $state.go('accessdenied').then(function() {
        //     LoginService.open();
        // });
        // now, send them to the signin state so they can log in
        const fromStateParams = this.ctxService.get('fromStateParams');
        if (fromState.name) {
          this.stateService.go(fromState.name, fromStateParams).then(() => {
            this.loginService.open();
          });
        } else {
          this.stateService.go('app.home').then(() => {
            this.loginService.open();
          });
        }
      }
    }
  }
}
