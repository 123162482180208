import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TextService } from 'app/services/text.service';

@Component({
  selector: 'category-name',
  template: '<span>{{categoryName}}</span>',
})
export class CategoryNameComponent implements OnInit, OnChanges {
  @Input() category;
  @Input() round;
  @Input() showSport;
  categoryName: string;

  constructor(private textService: TextService) {}

  private updateText() {
    this.categoryName = this.textService.categoryName(this.category, this.round, this.showSport);
  }

  ngOnInit() {
    this.updateText();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateText();
  }
}
