import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface IAccountService {
  get(): Promise<Object>;
  impersonate(data);
  getSettings(): Promise<Object>;
  saveSettings(settings): Promise<Object>;
}

@Injectable({ providedIn: 'root' })
export class AccountService implements IAccountService {
  constructor(private http: HttpClient) {}

  get(): Promise<any> {
    return this.http.get('api/account').toPromise();
  }

  getSettings(): Promise<any> {
    return this.http.get('api/account/settings').toPromise();
  }

  impersonate(data) {
    return this.http.post('api/account/impersonate?remember-me=true', data, { observe: 'response' }).toPromise();
  }

  saveSettings(settings): Promise<any> {
    return this.http.put('api/account/settings', settings).toPromise();
  }
}

Account.$inject = ['$resource'];

function Account($resource) {
  var service = $resource(
    'api/account',
    {},
    {
      get: {
        method: 'GET',
        params: {},
        isArray: false,
        interceptor: {
          response: function (response) {
            // expose response
            return response;
          },
        },
      },
      impersonate: {
        method: 'POST',
        url: 'api/account/impersonate',
      },
      getSettings: {
        method: 'GET',
        url: '/api/account/settings',
      },
      saveSettings: {
        method: 'PUT',
        url: '/api/account/settings',
      },
    }
  );

  return service;
}
