import { Directive, ElementRef } from '@angular/core';
import { PrincipalService } from 'app/services/auth/principal.service';
import { Role } from 'app/services/auth/role.constant';
import { AbstractAuthenticationDirective } from 'app/services/auth/abstract-authentication-directive';

@Directive({
  selector: '[isClubUser]',
})
export class IsClubUserDirective extends AbstractAuthenticationDirective {
  constructor(element: ElementRef, principalService: PrincipalService) {
    super(element, principalService);
  }

  elementVisible() {
    return this.principalService.hasAnyAuthority(Role.CLUB_USER_ROLES);
  }
}
