import { Component, Input, OnInit } from '@angular/core';

interface IClickMap {
  url: string;
  shapeCoords: string;
  shapeType: string;
  normalizedUrl?: string;
}

interface IAdImageDefinition {
  url: string;
  clickMap: IClickMap[];
}

interface IAdDefinition {
  large: IAdImageDefinition;
  small: IAdImageDefinition;
}

@Component({
  selector: 'advertisement',
  templateUrl: 'advertisement.html',
})
export class AdvertisementComponent implements OnInit {
  @Input() definition: IAdDefinition;

  ngOnInit() {
    this.addNormalizedUrls(this.definition.small);
    this.addNormalizedUrls(this.definition.large);
  }

  addNormalizedUrls(imgDef: IAdImageDefinition) {
    imgDef.clickMap?.forEach((clickMap) => {
      if (clickMap.url) {
        if (!/^http(s)?:\/\/.?.*/.test(clickMap.url.toLowerCase())) {
          clickMap.normalizedUrl = 'https://' + clickMap.url;
        } else {
          clickMap.normalizedUrl = clickMap.url;
        }
      }
    });
  }
}
