import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'scroll-up-button',
  template: `
    <button
      type="button"
      class="scroll-to-top"
      (click)="scrollUp()"
      [ngClass]="{ 'd-block': showScrollUp, 'd-none': !showScrollUp }"
    >
      <svg-icon icon="arrow-up"></svg-icon>
    </button>
  `,
})
export class ScrollUpButtonComponent {
  @Input() scrollTo: number;
  @Input() visibleAfter: number;
  showScrollUp = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showScrollUp = window.scrollY > this.visibleAfter;
  }

  scrollVertically(yPosition) {
    window.scroll({ left: 0, top: yPosition, behavior: 'smooth' });
  }

  scrollUp() {
    this.scrollVertically(this.scrollTo);
  }
}
