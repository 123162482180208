import { effect, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class TitleService {
  private title = signal('');

  constructor(private translateService: TranslateService) {
    effect(() => {
      document.title = this.translateService.instant(this.title());
    });
  }

  setTitle(title: string) {
    this.title.set(title);
  }
}
