import './polyfills';
import './components/alert/alert.component';

import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import '@angular/common/locales/global/fi';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ContentTypeInterceptor } from 'app/shared/content-type.interceptor';
import { QuillModule } from 'ngx-quill';
import { HomeModule, homeState } from 'app/home/home.module';
import { LoginModule } from 'app/features/login/login.module';
import { entityState } from 'app/entities/entity.state';
import { SortablejsModule } from 'nxt-sortablejs';
import { appState } from 'app/app.state';
import { NotificationInterceptor } from 'app/blocks/interceptor/notification.interceptor';
import { SportService } from 'app/services/sport/sport.service';
import { ownEventsFutureState } from 'app/entities/event/event.state';
import { UIRouterModule } from '@uirouter/angular';
import { KisanetAppComponent } from 'app/kisanet-app.component';
import { NavbarComponent } from 'app/layouts/navbar/navbar.component';
import { JhiLanguageComponent } from 'app/components/language/language.component';
import { CountryFlagComponent } from 'app/layouts/navbar/country-flag.component';
import { FooterComponent } from 'app/layouts/footer/footer.component';
import { NgbDropdownModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { resetRequestState } from 'app/account/reset/request/reset.request.state';
import { PasswordResetModule } from 'app/account/reset/request/password-reset.module';
import { accountState } from 'app/account/account.state';
import { AuthErrorInterceptor } from 'app/blocks/interceptor/auth-error.interceptor';
import { AuthModule } from 'app/shared/auth.module';
import { CacheBusterInterceptor } from 'app/blocks/interceptor/cache-buster.interceptor';
import { TawkChatWidgetComponent } from 'app/components/chat/tawk-chat-widget.component';
import { PageRibbonComponent } from 'app/services/profiles/page-ribbon.component';
import { CookieWindowComponent } from 'app/components/cookie-window/cookie-window.component';
import { resetFinishState } from 'app/account/reset/finish/reset.finish.state';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AppReloadToastComponent } from 'app/components/alert/app-reload-toast.component';
import { AppUpdateInterceptor } from 'app/blocks/interceptor/app-update.interceptor';
import { PrincipalService } from 'app/services/auth/principal.service';
import { AppReloadService } from 'app/services/app-reload.service';
import { datadogLogs } from '@datadog/browser-logs';
import { CoreModule } from 'app/core/core.module';
import { TitleService } from 'app/services/title.service';

let principalService: PrincipalService;
let appUpdateInterceptor: AppUpdateInterceptor;

if (
  !window.location.host.toLowerCase().startsWith('xlocalhost') &&
  !window.location.host.toLowerCase().startsWith('x127.0.0.1')
) {
  Bugsnag.start({
    apiKey: '65acf816252018b44085520fa5ba998e',
    onError: (event) => {
      event.addMetadata('appVersion', { value: appUpdateInterceptor.getCurrentAppHash() || 'unknown' });
      if (principalService?.isAuthenticated()) {
        const identity = principalService.getIdentity();
        event.addMetadata('user', {
          login: identity?.login,
          orgName: identity?.organization?.name,
        });
      }
    },
  });
}

datadogLogs.init({
  clientToken: 'pube19cd7a87f92b9db4617efe4cfab6cd2',
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  service: 'kisanet-client',
  telemetrySampleRate: 0,
  env: window.location.host || 'unknown',
});

export const eventDetailsFutureState = {
  name: 'app.entity.event.**',
  url: '/event',
  loadChildren: () => {
    console.log('Lazy-Loading EventPageModule');
    return import('./pages/event/event-page.module').then((m) => m.EventPageModule);
  },
};

export const myClubFutureState = {
  name: 'app.my-club.**',
  url: '/my-club',
  loadChildren: () => {
    console.log('Lazy-Loading MyClubpageModule');
    return import('./pages/my-club/my-clubpage.module').then((m) => m.MyClubpageModule);
  },
};

export const regDetailsFutureState = {
  name: 'app.entity.registration-detail.**',
  url: '/registration/:id',
  loadChildren: () => {
    console.log('Lazy-Loading RegistrationDetailsPageModule');
    return import('./pages/registration-details/registration-details-page.module').then(
      (m) => m.RegistrationDetailsPageModule
    );
  },
};

export const plannedEventsFutureState = {
  name: 'app.entity.planned-event.**',
  url: '/planned-event',
  loadChildren: () => {
    console.log('Lazy-Loading PlannedEventsPageModule');
    return import('./pages/planned-event/planned-events-page.module').then((m) => m.PlannedEventsPageModule);
  },
};

export const usersFutureState = {
  name: 'app.entity.user.**',
  url: '/user',
  loadChildren: () => {
    return import('./pages/users/users-page.module').then((m) => m.UsersPageModule);
  },
};

export const licenseFutureState = {
  name: 'app.license.**',
  url: '/license',
  loadChildren: () => {
    return import('./features/licenses/license.module').then((m) => m.LicenseModule);
  },
};

export const settingsFutureState = {
  name: 'app.settings.**',
  url: '/settings',
  loadChildren: () => {
    return import('./settings/settings-page.module').then((m) => m.SettingsPageModule);
  },
};

export const resultsFutureState = {
  name: 'app.event_results.**',
  url: '/results/:eventId',
  loadChildren: () => {
    return import('./features/public-results/public-results.module').then((m) => m.PublicResultsModule);
  },
};

export const publicEventFutureState = {
  name: 'app.entity.public-event-details.**',
  url: '/publicevent/:id',
  loadChildren: () => {
    return import('./features/registration/registration.module').then((m) => m.RegistrationModule);
  },
};

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

function sportServiceInitializer(sportService: SportService) {
  return () => sportService.loadData();
}

function errorHandlerFactory(service: PrincipalService) {
  principalService = service;
  return new BugsnagErrorHandler();
}

function createAppUpdateInterceptor(reloadService: AppReloadService) {
  appUpdateInterceptor = new AppUpdateInterceptor(reloadService);
  return appUpdateInterceptor;
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    UIRouterModule.forRoot({
      states: [
        appState,
        homeState,
        entityState,
        eventDetailsFutureState,
        myClubFutureState,
        regDetailsFutureState,
        ownEventsFutureState,
        plannedEventsFutureState,
        usersFutureState,
        licenseFutureState,
        settingsFutureState,
        resultsFutureState,
        publicEventFutureState,
        accountState,
        resetRequestState,
        resetFinishState,
      ],
      useHash: true,
      otherwise: { state: 'app.home' },
      config: (router) => {
        router.transitionService.onSuccess({}, (transition) => {
          const titleService = transition.injector().get(TitleService);
          const resolvedTitle = transition.injector().get('$pageTitle');
          if (resolvedTitle) {
            titleService.setTitle(resolvedTitle);
            return;
          }
          titleService.setTitle(transition.to().data?.pageTitle ?? 'global.title');
        });
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    QuillModule.forRoot(),
    HomeModule,
    LoginModule,
    SortablejsModule.forRoot({ animation: 150 }),
    NgbDropdownModule,
    PasswordResetModule,
    AuthModule,
    NgbToastModule,
    CoreModule,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'fi',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContentTypeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotificationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheBusterInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: createAppUpdateInterceptor,
      multi: true,
      deps: [AppReloadService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: sportServiceInitializer,
      deps: [SportService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: errorHandlerFactory,
      deps: [PrincipalService],
    },
  ],
  declarations: [
    FooterComponent,
    CountryFlagComponent,
    JhiLanguageComponent,
    NavbarComponent,
    KisanetAppComponent,
    TawkChatWidgetComponent,
    PageRibbonComponent,
    CookieWindowComponent,
    AppReloadToastComponent,
  ],
  bootstrap: [KisanetAppComponent],
  exports: [CountryFlagComponent],
})
export class AppModule {
  constructor(private translateService: TranslateService) {
    this.translateService.use('fi');
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);
