import { HttpEvent, HttpEventType, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AlertService } from 'app/components/alert/alert.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class NotificationInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (event) => {
          if (event.type === HttpEventType.Response) {
            const alertKey = event.headers.get('x-kisanet-alert');
            const alertParams = event.headers.get('x-kisanet-params');
            if (alertKey) {
              this.alertService.success(alertKey, { param: alertParams });
            }
          }
        },
      })
    );
  }
}
