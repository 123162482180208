import { ProfileService } from 'app/services/profiles/profile.service';
import { Component } from '@angular/core';

@Component({
  selector: 'page-ribbon',
  template: `<div *ngIf="ribbonEnv" class="ribbon">
    <a href="" [translate]="'global.ribbon.' + ribbonEnv">{{ ribbonEnv }}</a>
  </div>`,
})
export class PageRibbonComponent {
  ribbonEnv: string;
  constructor(private profileService: ProfileService) {
    this.profileService.getProfileInfo().then((response: any) => {
      if (response.ribbonEnv) {
        this.ribbonEnv = response.ribbonEnv;
      }
    });
  }
}
