<div class="input-w-icon__icon">
  <span
    class="events-filter"
    [ngClass]="{ 'active': selectedSportCount > 0 }"
    ngbDropdown
    placement="left-top"
    [autoClose]="true"
  >
    <button class="btn btn-default" ngbDropdownToggle>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.75 22.5H16.25C16.9375 22.5 17.5 21.9375 17.5 21.25C17.5 20.5625 16.9375 20 16.25 20H13.75C13.0625 20 12.5 20.5625 12.5 21.25C12.5 21.9375 13.0625 22.5 13.75 22.5ZM3.75 8.75C3.75 9.4375 4.3125 10 5 10H25C25.6875 10 26.25 9.4375 26.25 8.75C26.25 8.0625 25.6875 7.5 25 7.5H5C4.3125 7.5 3.75 8.0625 3.75 8.75ZM8.75 16.25H21.25C21.9375 16.25 22.5 15.6875 22.5 15C22.5 14.3125 21.9375 13.75 21.25 13.75H8.75C8.0625 13.75 7.5 14.3125 7.5 15C7.5 15.6875 8.0625 16.25 8.75 16.25Z"
          fill="#828282"
        />
      </svg>

      <div *ngIf="selectedSportCount > 0" class="icon-counter">
        <span class="icon-counter__value">{{selectedSportCount}}</span>
      </div>
    </button>

    <div class="dropdown-menu sh-block events-filter__main" ngbDropdownMenu aria-labelledby="simple-dropdown">
      <div class="events-filter__section">
        <h3 class="events-filter__heading" [translate]="'event.sports'">Sports</h3>
        <div class="events-filter__section-content events-filter-sports">
          <div *ngFor="let sportId of sportIds; let $index = index;" class="custom-checkbox">
            <input
              class="visually-hidden"
              type="checkbox"
              [(ngModel)]="filters[sportId]"
              (ngModelChange)="onFiltersChange()"
              id="filter-checkbox-{{$index}}"
            />
            <label for="filter-checkbox-{{$index}}">
              {{ 'sport.' + sportId | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </span>
</div>
