import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TextService {
  constructor(private translateService: TranslateService) {}

  categoryName(category, round, showSport: boolean): string {
    const roundName = round ? '/' + round.name : '';
    if (!category) {
      return '';
    } else {
      let sportPrefix = '';
      if (showSport) {
        const sport = this.translateService.instant('sport.short.' + category.sportType);
        sportPrefix = sport + ': ';
      }
      if (category.type === 'DEFAULT') {
        return sportPrefix + category.name + roundName;
      } else if (category.type === 'PERFORMANCE') {
        return category.name;
      } else {
        let suffix = this.translateService.instant('sport.categoryType.' + category.type);
        suffix = suffix ? ', ' + suffix : '';
        return sportPrefix + category.name + suffix;
      }
    }
  }
}
