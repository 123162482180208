import { Component } from '@angular/core';
import { AppSettingsService } from 'app/services/app-settings.service';

@Component({
  selector: 'front-page-banner',
  templateUrl: 'front-page-banner.html',
})
export class FrontPageBannerComponent {
  banner;

  constructor(appSettingsService: AppSettingsService) {
    appSettingsService.get().then((settings) => {
      const banners = settings.banners;
      if (banners) {
        const bannerDef = banners.find((bDef) => bDef.displaySettings?.showOnFrontPage);
        if (bannerDef?.banner?.large?.url || bannerDef?.banner?.small?.url) {
          this.banner = bannerDef.banner;
        }
      }
    });
  }
}
