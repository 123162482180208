import { AccountService } from 'app/services/auth/account.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IPrincipalService {
  authenticate(identity);
  hasAnyAuthority(authorities: string[]);
  hasAuthority(authority: string): Promise<boolean>;
  identity();
  getIdentity();
  isAuthenticated(): boolean;
  isIdentityResolved(): boolean;
  hasAuthoritySync(authority: string): boolean;
  getCurrentUserOrgId(): number;
}

@Injectable({ providedIn: 'root' })
export class PrincipalService implements IPrincipalService {
  _identity = undefined;
  _authenticated: boolean;
  private identitySubject = new Subject();

  constructor(private accountService: AccountService) {
    this.isAuthenticated = () => {
      return this._authenticated;
    };
  }

  public authenticate(identity) {
    this._identity = identity;
    this._authenticated = !!identity;
    this.identitySubject.next(this._identity);
  }

  public hasAnyAuthority(authorities: string[]) {
    if (!this._authenticated || !this._identity || !this._identity.authorities) {
      return false;
    }

    if (this._identity.authorities.includes('ROLE_SYSTEM_ADMIN')) {
      return true;
    }

    for (let i = 0; i < authorities.length; i++) {
      if (this._identity.authorities.indexOf(authorities[i]) !== -1) {
        return true;
      }
    }

    return false;
  }

  public hasAuthority(authority: string) {
    if (!this._authenticated) {
      return Promise.resolve(false);
    }

    return this.identity().then(
      function (_id) {
        return (
          _id.authorities &&
          (_id.authorities.indexOf(authority) !== -1 || _id.authorities.indexOf('ROLE_SYSTEM_ADMIN') !== -1)
        );
      },
      function () {
        return false;
      }
    );
  }

  hasAuthoritySync(authority) {
    if (!this._identity) {
      return false;
    }
    const _id = this._identity;
    return (
      _id.authorities &&
      (_id.authorities.indexOf(authority) !== -1 || _id.authorities.indexOf('ROLE_SYSTEM_ADMIN') !== -1)
    );
  }

  getCurrentIdentity(): Observable<any> {
    return new Observable<any>((subscriber) => {
      subscriber.next(this._identity);
      this.identitySubject.subscribe(subscriber);
    });
  }

  public getIdentity() {
    return this._identity;
  }

  public identity(force = false): Promise<any> {
    return new Promise((resolve) => {
      if (force === true) {
        this._identity = undefined;
        this.identitySubject.next(undefined);
      }

      // check and see if we have retrieved the identity data from the server.
      // if we have, reuse it by immediately resolving
      if (this._identity !== undefined) {
        resolve(this._identity);
        return;
      }

      // retrieve the identity data from the server, update the identity object, and then resolve.

      this.accountService
        .get()
        .then((account) => {
          this._identity = account;
          this._authenticated = true;
          resolve(this._identity);
          this.identitySubject.next(this._identity);
        })
        .catch(() => {
          this._identity = null;
          this._authenticated = false;
          resolve(this._identity);
          this.identitySubject.next(this._identity);
        });
    });
  }

  isAuthenticated(): boolean {
    return this._authenticated;
  }

  public isIdentityResolved() {
    return this._identity !== undefined;
  }

  public getCurrentUserOrgId(): number {
    const currentUser = this.getIdentity();
    return currentUser && currentUser.login ? currentUser.organization.id : null;
  }
}
