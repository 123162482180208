import { Component, Input } from '@angular/core';

const FLAG_DIR_PATH = ['content', 'images', 'server-view', 'flag'];

@Component({
  selector: 'country-flag',
  template: '<img *ngIf="countryCode" [src]="flagForLang()">',
})
export class CountryFlagComponent {
  @Input() countryCode: string;

  flagForLang(): string {
    const normalizedCode = this.countryCode?.toLowerCase();
    switch (normalizedCode) {
      case 'fi':
        return this.flagImage('FI');
      case 'en':
        return this.flagImage('GB');
    }
  }

  flagImage(fileBaseName: string) {
    return FLAG_DIR_PATH.join('/') + '/' + fileBaseName + '.svg';
  }
}
