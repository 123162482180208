import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SportService } from 'app/services/sport/sport.service';
import { UtilsService } from 'app/services/utils.service';

@Component({
  selector: 'sport-filter',
  templateUrl: 'sport-filter.html',
})
export class SportFilterComponent implements OnInit {
  sportIds: string[] = [];
  selectedSportCount: number = 0;
  @Input() filters: Record<string, boolean> = {};
  @Output() filtersChange = new EventEmitter<Record<string, boolean>>();

  constructor(private sportService: SportService, private utilsService: UtilsService) {
    this.sportIds = this.utilsService.sortByLocalizedStrings(sportService.disciplineIds(), (id) => 'sport.' + id);
  }

  ngOnInit() {
    this.onFiltersChange();
  }

  onFiltersChange() {
    const selectedSportIds = Object.keys(this.filters).filter((sportId) => this.filters[sportId]);
    this.selectedSportCount = selectedSportIds.length;
    this.filtersChange.emit(this.filters);
  }
}
