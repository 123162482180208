import { Component, Input } from '@angular/core';
import { ICONS } from 'app/components/util/svg-icon.directive';

@Component({
  selector: 'svg-icon',
  template: '<span [innerHTML]="icons[icon] | safeHtml"></span>',
})
export class SvgIcon {
  @Input() icon!: string;

  icons = ICONS;
}
