import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/services/auth/auth.service';
import { StateService } from '@uirouter/core';

@Component({
  templateUrl: 'login-dialog.html',
})
export class LoginDialogComponent implements AfterViewInit {
  authenticationError = false;
  credentials = {};
  password = null;
  rememberMe = true;
  username = null;
  @Output() close = new EventEmitter();
  @Output() dismiss = new EventEmitter();
  @ViewChild('usernameInput') usernameInput: ElementRef;

  constructor(
    private activeModal: NgbActiveModal,
    private authService: AuthService,
    private stateService: StateService
  ) {}

  ngAfterViewInit() {
    this.usernameInput.nativeElement.focus();
  }

  cancel() {
    this.credentials = {
      username: null,
      password: null,
    };
    this.authenticationError = false;
    // this.dismiss.emit();
    this.activeModal.dismiss();
  }

  login(event) {
    event.preventDefault();
    this.authService
      .login(this.username, this.password)
      .then((success) => {
        this.authenticationError = !success;
        if (success) {
          this.activeModal.close(true);
          // AppEvents.emit('authenticationSuccess');

          // previousState was set in the authExpiredInterceptor before being redirected to login modal.
          // since login is successful, go to stored previousState and clear previousState
          const previousState = this.authService.getPreviousState();
          if (previousState) {
            this.authService.resetPreviousState();
            this.stateService.go(previousState.name, previousState.params, { reload: true });
          }
        }
      })
      .catch(() => {
        this.authenticationError = true;
      });
  }

  requestResetPassword(event) {
    event.preventDefault();
    this.activeModal.dismiss();
    this.stateService.go('app.account.requestReset');
  }
}
