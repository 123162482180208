import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginDialogComponent } from 'app/components/login/login-dialog.component';

@Injectable()
export class LoginServiceNg2 {
  modalOpen = false;

  constructor(private ngbModal: NgbModal) {}

  open() {
    if (this.modalOpen) {
      return;
    }
    this.modalOpen = true;

    const resetModal = (result) => {
      this.modalOpen = false;
      return result;
    };

    return this.ngbModal.open(LoginDialogComponent).result.then(resetModal, resetModal);
  }
}
