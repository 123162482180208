import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PasswordResetFinishService {
  constructor(private readonly http: HttpClient) {}

  finishReset(keyAndPassword): Promise<any> {
    return this.http.post('api/account/reset_password/finish', keyAndPassword).toPromise();
  }
}
