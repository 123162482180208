import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PasswordResetInitService {
  constructor(private http: HttpClient) {}

  save(login: string): Promise<any> {
    return this.http.post('api/account/reset_password/init', login).toPromise();
  }
}

// legacyComponents.factory('PasswordResetInit', PasswordResetInit);

PasswordResetInit.$inject = ['$resource'];

function PasswordResetInit($resource) {
  var service = $resource(
    'api/account/reset_password/init',
    {},
    {
      save: {
        method: 'POST',
        transformResponse: function (response) {
          return JSON.parse(response);
        },
      },
    }
  );

  return service;
}
