<div class="container-fluid row event-item__col">
  <div class="col-lg-2 col-3 event-item__img-container d-none d-lg-block d-sm-block d-md-block">
    <div class="event-item__img" [ngStyle]="imageContainerStyle"></div>

    <div class="event-item__img-overlay">
      <span class="d-none d-sm-flex">{{ event.startDate | date: 'd' }}</span>
      <span class="d-none d-sm-flex">{{ event.startDate | date: 'MMM' }}</span>
    </div>
  </div>

  <div class="col-lg-10 col-xs event-item__col">
    <div class="col event-item__col event-item__info-container">
      <div class="row">
        <div class="col col-lg-9 col-sm-9 col-md-9">
          <a
            uiSref="app.entity.event.detail"
            [uiParams]="{id:event.id}"
            class="event-item__title"
            [ngClass]="{'event-item__title__cancelled': event.cancelled}"
          >
            <event-label *ngIf="event.settings.virtual" type="VIRTUAL"></event-label>
            <event-label *ngIf="event.settings.online" type="ONLINE"></event-label>
            <span>{{event.name}}</span>
          </a>
        </div>
        <div class="w-100 d-block d-lg-none d-sm-none d-md-none"></div>
        <div
          class="col col-lg-3 col-sm-3 col-md-3 d-none d-lg-block d-sm-block d-md-block event-item__info-item event-item__event-date"
        >
          <event-date [event]="event"></event-date>
        </div>
      </div>
      <div class="row event-item__subtitle mb-3">
        <div *ngIf="event.description" class="col">
          {{event.description}}
        </div>
      </div>
      <div class="row">
        <div class="col-lg-9 col-md-8">
          <div class="row event-item__info">
            <div class="d-block d-lg-none d-sm-none d-md-none col event-item__info-item">
              <div class="label" [translate]="'event.date'">DATE</div>
              <event-date [event]="event"></event-date>
            </div>
            <div class="col-lg-6 col-12 event-item__info-item">
              <div class="label" [translate]="'event.location'">PLACE</div>
              <div class="value" *ngIf="event.location">
                <span>{{event.location}}</span><span *ngIf="event.city">, {{event.city}} </span>
              </div>
              <div class="value" *ngIf="!event.location">
                <span *ngIf="event.city">{{event.city}}</span>
                <span *ngIf="!event.city">-</span>
              </div>
            </div>
            <div class="col-lg-6 event-item__info-item">
              <div class="label" [translate]="'event.registrationDates'">ILMOITTAUTUMINEN</div>
              <div class="value">
                {{event.registrationStartDate | date:'mediumDate'}} - {{event.registrationEndDate1 | date:'mediumDate'}}
              </div>
            </div>
          </div>

          <div class="row event-item__info">
            <div class="col-lg-6 col-12 order-last order-lg-first event-item__info-item">
              <div class="label" [translate]="'event.organizer'">Organizer</div>
              <div class="value" *ngIf="event.organizer">{{event.organizer.name}}</div>
              <div *ngIf="!event.organizer">-</div>
            </div>
            <div *ngIf="hasSecondPhaseRegistration()" class="col-lg-6 order-first order-lg-last event-item__info-item">
              <div class="label" [translate]="'event.registrationDates2'">JÄLKI-ILMOITTAUTUMINEN</div>
              <div class="value">
                {{registrationPhase2StartDate | date:'mediumDate'}} - {{event.registrationEndDate2 | date:'mediumDate'}}
              </div>
            </div>
          </div>
        </div>

        <!--                categories list mobile-->
        <div *ngIf="showCategoriesOnMobile" class="col d-flex d-sm-none event-item__tags">
          <div
            *ngFor="let c of categories"
            class="event-item__tags-item"
            [ngClass]="'event-item__tags-item-' + c.sportType"
          >
            <span class="type">{{'sport.short.' + c.sportType | translate}}</span>
            <span>{{c.name}}</span>
          </div>
        </div>
        <div *ngIf="!showCategoriesOnMobile" class="col d-flex d-sm-none event-item__tags">
          <div
            *ngFor="let entry of categoriesCountBySportType | keyvalue"
            class="event-item__tags-item"
            [ngClass]="'event-item__tags-item-' + entry.key"
          >
            <span class="type">{{'sport.short.' + entry.key | translate}}</span>
            <span>{{entry.value}}</span>
          </div>
        </div>

        <!-- Buttons -->
        <div class="col-lg-3 col-md-4 event-item__btns">
          <button
            *ngIf="showResults && categoriesWithResults.length > 0 && categoriesWithInternalResults.length === 0"
            class="btn btn-primary btn-block btn-sm-fs"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle="dropdown"
            [translate]="'event.detail.results'"
            (click)="openExtCategoryResults()"
          >
            Tulokset
          </button>

          <button
            *ngIf="showResults && categoriesWithResults.length === 1 && categoriesWithInternalResults.length === 1"
            class="btn btn-primary btn-block btn-sm-fs"
            aria-haspopup="true"
            aria-expanded="false"
            data-toggle="dropdown"
            [translate]="'event.detail.results'"
            uiSref="app.event_results.category_results"
            [uiParams]="{eventId: event.id, categoryId: categoriesWithInternalResults[0].id}"
          >
            Tulokset
          </button>

          <div
            *ngIf="showResults && categoriesWithResults.length > 1 && categoriesWithInternalResults.length > 0"
            class="btn-block"
            ngbDropdown
          >
            <button
              id="resultsDdToggleButton"
              type="button"
              class="btn btn-primary btn-block btn-sm-fs"
              ngbDropdownToggle
            >
              {{'event.detail.results' | translate}} <span class="caret"></span>
            </button>
            <ul ngbDropdownMenu role="menu" aria-labelledby="resultsDdToggleButton">
              <li
                *ngFor="let cat of categoriesWithInternalResults"
                role="menuitem"
                class="dropdown-item"
                uiSref="app.event_results.category_results"
                [uiParams]="{eventId: event.id, categoryId: cat.id}"
                ngbDropdownItem
              >
                <category-name [category]="cat" [showSport]="sportWithResultsCount > 1"></category-name>
              </li>
              <li
                *ngFor="let cat of categoriesWithExternalResults"
                role="menuitem"
                class="dropdown-item"
                (click)="openExtCategoryResults()"
                ngbDropdownItem
              >
                <category-name [category]="cat" [showSport]="sportWithResultsCount > 1"></category-name>
              </li>
            </ul>
          </div>

          <div class="btn-block">
            <button
              *ngIf="showRegisterDefault"
              id="registerDefaultButton"
              uiSref="app.entity.public-event-details.register.internal"
              [uiParams]="{id:event.id}"
              class="btn btn-primary btn-block btn-sm-fs"
              [translate]="'event.register.register'"
            >
              Ilmoittaudu
            </button>
            <div *ngIf="showRegisterWithOptions" ngbDropdown>
              <button
                ngbDropdownToggle
                id="registerDdToggleButton"
                class="btn btn-primary btn-block btn-sm-fs"
                [translate]="'event.register.register'"
              >
                Ilmoittaudu
              </button>
              <ul ngbDropdownMenu role="menu" aria-labelledby="registerDdToggleButton">
                <li
                  role="menuitem"
                  class="dropdown-item"
                  uiSref="app.entity.public-event-details.register.internal"
                  [uiParams]="{id:event.id}"
                  [translate]="'event.register.withLogin'"
                  ngbDropdownItem
                >
                  With login
                </li>
                <li
                  role="menuitem"
                  class="dropdown-item"
                  uiSref="app.entity.public-event-details.register.external"
                  [uiParams]="{id:event.id}"
                  [translate]="'event.register.withoutLogin'"
                  ngbDropdownItem
                >
                  Without login
                </li>
              </ul>
            </div>
          </div>

          <div class="btn-block">
            <div has-any-authority="ROLE_CLUB_USER,ROLE_FED_USER">
              <button
                *ngIf="showRegisterJudges"
                uiSref="app.entity.public-event-details.register-judge2"
                [uiParams]="{id:event.id}"
                class="btn btn-outline-primary btn-block btn-sm-fs"
                [translate]="'event.register.registerJudges'"
              >
                Ilmoita tuomarit
              </button>
            </div>
          </div>

          <div *ngIf="toDetailedInfo" class="btn-block d-none d-sm-block">
            <div *isEventAdmin>
              <button
                [translate]="'event.detail.toCompetitionMode'"
                uiSref="app.entity.event.detail.event-competition.main"
                [uiParams]="{id: event.id, startlistId: null}"
                class="btn btn-outline-primary btn-block btn-sm-fs"
              >
                Run competition
              </button>
            </div>
          </div>

          <div *isEventAdmin class="btn-block d-none d-sm-block">
            <div>
              <button
                [translate]="'entity.action.edit'"
                uiSref="app.entity.event.detail.edit"
                [uiParams]="{id: event.id}"
                class="btn btn-outline-primary btn-block btn-sm-fs"
              >
                EDIT
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--            categories list desktop-->
      <div class="row d-none d-sm-block">
        <div class="col-12 d-flex event-item__tags">
          <div
            *ngFor="let c of categories"
            class="event-item__tags-item"
            [ngClass]="'event-item__tags-item-' + c.sportType"
          >
            <span class="type">{{'sport.short.' + c.sportType | translate}}</span>
            <span>{{c.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
