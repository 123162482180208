<div class="header sh-block">
  <nav class="container navbar justify-content-between">
    <div class="header__logo logo-wrapper d-flex">
      <div>
        <a href="#" class="logo-wrapper__logo navbar-brand">
          <svg
            class="d-none d-sm-block"
            width="123"
            height="26"
            viewBox="0 0 123 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="header-logo-letter"
              d="M17.6202 2.14264V1.99976H12.473L4.14171 11.2398V1.99976H0V23.8615H4.14171V14.1928L13.3349 23.8615H18.4342V23.6234L7.87643 12.5972L17.6202 2.14264Z"
              fill="#003052"
            />
            <path class="header-logo-letter" d="M23.5836 8.02484V23.4805H27.4141V8.02484H23.5836Z" fill="#003052" />
            <path
              class="header-logo-letter"
              d="M44.4373 9.88238C42.6657 8.31062 40.8223 7.83433 38.4043 7.83433C35.5793 7.83433 31.8925 9.0965 31.8925 12.6449C31.8925 16.1456 35.3639 17.2173 38.2846 17.4316C40.4871 17.5507 41.3729 18.0031 41.3729 19.051C41.3729 20.1703 40.0083 20.9323 38.5719 20.9085C36.8482 20.8609 34.3344 19.9559 33.2332 18.7414L31.3658 21.4563C33.6162 23.7901 36.0582 24.1949 38.5001 24.1949C42.9291 24.1949 45.1555 21.8611 45.1555 19.1462C45.1555 15.0501 41.4687 14.4071 38.548 14.2166C36.5609 14.0976 35.6512 13.526 35.6512 12.5258C35.6512 11.5494 36.6567 11.0017 38.4761 11.0017C39.9605 11.0017 41.2054 11.3351 42.3066 12.3829L44.4373 9.88238Z"
              fill="#003052"
            />
            <path
              class="header-logo-letter"
              d="M61.7499 10.2158C60.888 8.69165 58.901 7.71525 56.8181 7.71525C52.3173 7.69144 48.7741 10.4777 48.7741 15.7884C48.7741 21.1705 52.1497 23.9806 56.7463 23.9568C58.47 23.933 60.888 23.0518 61.7499 21.2419L61.9414 23.4805H65.5564V8.09628H61.8935L61.7499 10.2158ZM57.1772 11.1207C63.2102 11.1207 63.2102 20.456 57.1772 20.456C54.6395 20.456 52.5807 18.7176 52.5807 15.7884C52.5807 12.8354 54.6395 11.1207 57.1772 11.1207Z"
              fill="#003052"
            />
            <path
              class="header-logo-letter"
              d="M81.8302 23.8377H85.6607V15.7408C85.6607 10.9064 82.7399 8.19154 79.1009 8.19154C77.1618 8.19154 75.5817 8.95361 74.0256 10.5016L73.7622 8.40587H70.3627V23.8377H74.1453V15.9551C74.1453 13.6213 75.7493 11.6447 78.0955 11.6447C80.5613 11.6447 81.8302 13.4307 81.8302 15.7646V23.8377Z"
              fill="#003052"
            />
            <path
              class="header-logo-letter"
              d="M94.4285 17.1458H106.495C107.093 10.8111 103.861 7.61999 98.6899 7.61999C93.6624 7.61999 90.4783 11.0017 90.4783 15.6931C90.4783 20.6227 93.6385 23.8853 98.9772 23.8853C101.323 23.8853 104.005 23.0756 105.633 21.4086L103.167 19.0034C102.305 19.9083 100.438 20.4322 99.0251 20.4322C96.3437 20.4322 94.6679 19.051 94.4285 17.1458ZM94.4764 14.0023C95.0509 12.0019 96.7507 11.0017 98.8096 11.0017C101.012 11.0017 102.592 12.0019 102.832 14.0023H94.4764Z"
              fill="#003052"
            />
            <path
              class="header-logo-letter"
              d="M114.262 4.3574V8.31062H111.389V11.597H114.262V18.2413C114.262 22.0754 116.464 23.9806 119.816 23.8615C120.989 23.8377 121.899 23.6472 123 23.2185L121.923 19.9559C121.348 20.2417 120.678 20.4322 120.079 20.4322C118.882 20.4322 118.068 19.7178 118.068 18.2413V11.597H122.354V8.31062H118.092V3.95255L114.262 4.3574Z"
              fill="#003052"
            />
            <path
              id="header-logo-dot"
              d="M23.2485 3.76203C23.2485 6.71504 27.7493 6.71504 27.7493 3.76203C27.7493 0.785214 23.2485 0.785214 23.2485 3.76203Z"
              fill="#57B5DC"
            />
          </svg>
          <svg class="d-block d-sm-none" xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 40 40" width="40">
            <path
              d="M 16.467796,36 V 24.113658 h 7.233899 V 36 h 9.042373 V 20.151545 h 5.425423 L 20.084746,2.3220335 2,20.151545 H 7.4254237 V 36 Z"
              id="path2"
              style="stroke-width: 1.89279985; fill: #003052;"
            />
            <path d="M 0,16 H 24 V 40 H 0 Z" id="path4" style="fill: none;" />
          </svg>
        </a>
      </div>
      <div class="logo-wrapper__lang">
        <jhi-language></jhi-language>
      </div>
    </div>

    <div class="d-flex justify-content-end flex-grow-1">
      <!-- menu body -->
      <div
        [ngClass]="{'d-block': !isNavbarCollapsed, 'd-none': isNavbarCollapsed}"
        class="d-lg-flex order-2 order-lg-0 navbar-collapse"
      >
        <ul class="header__nav-links nav ml-auto" *ngIf="isAuthenticated()">
          <div class="d-none d-lg-flex">
            <li isClubUser uiSrefActive="active" class="header__nav-item nav-item">
              <a
                class="text-upper text-bold nav-link"
                uiSref="app.my-club"
                uiSrefActive="active"
                [translate]="'global.menu.myClub'"
              >
                My club
              </a>
            </li>
          </div>

          <div class="d-none d-lg-flex">
            <li isClubUser uiSrefActive="active" class="header__nav-item nav-item">
              <a
                class="text-upper text-bold nav-link"
                uiSref="app.entity.event"
                uiSrefActive="active"
                [translate]="'global.menu.myEvents'"
              >
                My events
              </a>
            </li>
          </div>

          <div class="d-none d-lg-flex">
            <li isAuthenticated uiSrefActive="active" class="header__nav-item nav-item">
              <a
                class="text-upper text-bold nav-link"
                uiSref="app.entity.planned-event"
                uiSrefActive="active"
                [translate]="'global.menu.plannedEvent'"
              >
                Events to apply
              </a>
            </li>
          </div>

          <div class="d-lg-flex">
            <li
              class="header__nav-item nav-item"
              uiSrefActive="active"
              hasAnyAuthority="ROLE_FED_ADMIN, ROLE_CLUB_ADMIN"
            >
              <a
                uiSref="app.entity.user"
                uiSrefActive="active"
                (click)="collapseNavbar()"
                class="text-upper text-bold nav-link"
                [translate]="'global.menu.entities.users'"
              >
                Users
              </a>
            </li>
          </div>

          <!--                    <div class="d-lg-flex">-->
          <!--                        <li has-authority="ROLE_SYSTEM_ADMIN" class="header__nav-item nav-item">-->
          <!--                            <a-->
          <!--                                class="text-upper text-bold nav-link"-->
          <!--                                uiSref="app.entity.judge"-->
          <!--                                uiSrefActive="active"-->
          <!--                                [translate]="global.menu.entities.judges"-->
          <!--                            >-->
          <!--                                Judges-->
          <!--                            </a>-->
          <!--                        </li>-->
          <!--                    </div>-->

          <div class="d-lg-flex">
            <li hasAnyAuthority="ROLE_FED_USER, ROLE_SYSTEM_ADMIN" class="header__nav-item nav-item">
              <a
                class="text-upper text-bold nav-link"
                uiSref="app.license"
                uiSrefActive="active"
                (click)="collapseNavbar()"
                [translate]="'global.menu.entities.licenses'"
              >
                Licenses
              </a>
            </li>
            <li hasAnyAuthority="ROLE_FED_USER, ROLE_SYSTEM_ADMIN" class="header__nav-item nav-item">
              <a
                class="text-upper text-bold nav-link"
                uiSref="app.settings"
                uiSrefActive="active"
                (click)="collapseNavbar()"
                [translate]="'global.menu.settings.main'"
              >
                Settings
              </a>
            </li>
          </div>
          <div class="d-lg-flex">
            <li *ngIf="isAuthenticated()" class="header__nav-item nav-item">
              <a
                class="text-upper text-bold nav-link"
                [translate]="'global.menu.help'"
                (click)="collapseNavbar()"
                href="https://docs.kisanet.fi"
                target="_blank"
              >
                Ohjeet
              </a>
            </li>
          </div>
        </ul>
      </div>

      <!-- login/logout -->
      <div class="order-0 order-lg-1 flex-grow-1">
        <div *ngIf="isAuthenticated()" class="nav-link d-flex justify-content-end flex-grow-1 user">
          <div ngbDropdown>
            <a class="header__nav-login" ngbDropdownToggle>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12 12C14.21 12 16 10.21 16 8C16 5.79 14.21 4 12 4C9.79 4 8 5.79 8 8C8 10.21 9.79 12 12 12ZM12 14C9.33 14 4 15.34 4 18V19C4 19.55 4.45 20 5 20H19C19.55 20 20 19.55 20 19V18C20 15.34 14.67 14 12 14Z"
                  fill="white"
                />
              </svg>
            </a>

            <ul class="dropdown-menu" ngbDropdownMenu aria-labelledby="simple-dropdown">
              <li (click)="logout()" class="dropdown-item" ngbDropdownItem>
                <span [translate]="'global.menu.account.logout'">Sign out</span>
              </li>
            </ul>
          </div>
          <div *ngIf="principal" class="flex-grow-1 user-name">
            <span ellipsis class="d-inline d-sm-none">{{principal.firstName}}</span>
            <span class="d-none d-sm-inline">{{principal.firstName}}</span>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-primary login-btn"
          *ngIf="!isAuthenticated()"
          [translate]="'global.menu.account.login'"
          (click)="login()"
        >
          Sign in
        </button>
      </div>

      <!-- mobile menu toggler -->
      <div class="d-block d-lg-none order-1 menu-toggler-container">
        <button type="button" class="navbar-toggler" *ngIf="isAuthenticated()" (click)="toggleNavbar()">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </div>
  </nav>
</div>
