export const Role = {
  ROLE_FED_USER: 'ROLE_FED_USER',
  ROLE_CLUB_ADMIN: 'ROLE_CLUB_ADMIN',
  CLUB_USER_ROLES: ['ROLE_CLUB_USER', 'ROLE_CLUB_EVENT_ADMIN', 'ROLE_CLUB_ADMIN'],
  EVENT_ADMIN_USER_ROLES: [
    'ROLE_CLUB_EVENT_ADMIN',
    'ROLE_CLUB_ADMIN',
    'ROLE_FED_USER',
    'ROLE_FED_ADMIN',
    'ROLE_SYSTEM_ADMIN',
  ],
  CLUB_EVENT_ADMIN_ROLES: ['ROLE_CLUB_EVENT_ADMIN', 'ROLE_CLUB_ADMIN'],
  FED_ROLES: ['ROLE_FED_USER', 'ROLE_FED_ADMIN'],
  USER_ADMIN_ROLES: ['ROLE_CLUB_ADMIN', 'ROLE_FED_ADMIN'],
  ROLE_SYSTEM_ADMIN: 'ROLE_SYSTEM_ADMIN',
  REGISTER_ROLES: ['ROLE_CLUB_USER', 'ROLE_FED_USER'],
};

// legacyComponents.constant('Role', Role);
