import { Component } from '@angular/core';

@Component({
  selector: 'front-page-carousel',
  templateUrl: 'front-page-carousel.html',
})
export class FrontPageCarouselComponent {
  slides: any[];
  active: number;

  constructor() {
    // let flavor = 'default';

    const parts = window.location.hostname.split('.');
    const flavor = parts.length > 2 ? parts[parts.length - 3] : 'default';
    // if (parts.length > 2) {
    //   flavor = parts[parts.length - 3];
    // }

    if (flavor === 'tul') {
      this.slides = [{ image: 'https://static.kisanet.fi/front-carousel/tul/1.jpg', id: 0, text: 'Text' }];
      this.active = 0;
    } else {
      const baseUrl = 'https://kisanet-static-content.fra1.cdn.digitaloceanspaces.com/front-carousel/';
      this.slides = [
        { image: baseUrl + '1.jpg', id: 0, text: 'Text' },
        { image: baseUrl + '3.jpg', id: 1, text: 'Text' },
        { image: baseUrl + '4.jpg', id: 2, text: 'Text' },
        { image: baseUrl + '5.jpg', id: 3, text: 'Text' },
        { image: baseUrl + '6.jpg', id: 4, text: 'Text' },
        { image: baseUrl + '7.jpg', id: 5, text: 'Text' },
        { image: baseUrl + '8.jpg', id: 6, text: 'Text' },
        { image: baseUrl + '9.jpg', id: 7, text: 'Text' },
      ];
      this.active = Math.floor(Math.random() * this.slides.length);
    }
  }
}
