import { Colors } from 'app/styles';

export const ICONS = {
  caret:
    '<svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M0.710051 1.71L3.30005 4.3C3.69005 4.69 4.32005 4.69 4.71005 4.3L7.30005 1.71C7.93005 1.08 7.48005 0 6.59005 0H1.41005C0.520051 0 0.0800515 1.08 0.710051 1.71Z" fill="#F58220"/>' +
    '</svg>',

  plus:
    '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z" fill="#003052"/>' +
    '</svg>',

  'plus-16':
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M12.0002 8.66668H8.66683V12C8.66683 12.3667 8.36683 12.6667 8.00016 12.6667C7.6335 12.6667 7.3335 12.3667 7.3335 12V8.66668H4.00016C3.6335 8.66668 3.3335 8.36668 3.3335 8.00001C3.3335 7.63334 3.6335 7.33334 4.00016 7.33334H7.3335V4.00001C7.3335 3.63334 7.6335 3.33334 8.00016 3.33334C8.36683 3.33334 8.66683 3.63334 8.66683 4.00001V7.33334H12.0002C12.3668 7.33334 12.6668 7.63334 12.6668 8.00001C12.6668 8.36668 12.3668 8.66668 12.0002 8.66668Z"' +
    'fill="#57B5DC"/>' +
    '</svg>',

  save:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M17.59 3.59C17.21 3.21 16.7 3 16.17 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V7.83C21 7.3 20.79 6.79 20.41 6.42L17.59 3.59ZM12 19C10.34 19 9 17.66 9 16C9 14.34 10.34 13 12 13C13.66 13 15 14.34 15 16C15 17.66 13.66 19 12 19ZM13 9H7C5.9 9 5 8.1 5 7C5 5.9 5.9 5 7 5H13C14.1 5 15 5.9 15 7C15 8.1 14.1 9 13 9Z" fill="#003052"/>' +
    '</svg>',

  cancel:
    '<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M13.3002 0.709971C12.9102 0.319971 12.2802 0.319971 11.8902 0.709971L7.00022 5.58997L2.11022 0.699971C1.72022 0.309971 1.09021 0.309971 0.700215 0.699971C0.310215 1.08997 0.310215 1.71997 0.700215 2.10997L5.59022 6.99997L0.700215 11.89C0.310215 12.28 0.310215 12.91 0.700215 13.3C1.09021 13.69 1.72022 13.69 2.11022 13.3L7.00022 8.40997L11.8902 13.3C12.2802 13.69 12.9102 13.69 13.3002 13.3C13.6902 12.91 13.6902 12.28 13.3002 11.89L8.41021 6.99997L13.3002 2.10997C13.6802 1.72997 13.6802 1.08997 13.3002 0.709971Z" fill="#EB5757"/>' +
    '</svg>',

  edit:
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M3 17.4625V20.5025C3 20.7825 3.22 21.0025 3.5 21.0025H6.54C6.67 21.0025 6.8 20.9525 6.89 20.8525L17.81 9.9425L14.06 6.1925L3.15 17.1025C3.05 17.2025 3 17.3225 3 17.4625ZM20.71 7.0425C21.1 6.6525 21.1 6.0225 20.71 5.6325L18.37 3.2925C17.98 2.9025 17.35 2.9025 16.96 3.2925L15.13 5.1225L18.88 8.8725L20.71 7.0425Z" fill="#003052"/>' +
    '</svg>',

  'edit-16': `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M2 11.6417V13.6683C2 13.855 2.14667 14.0017 2.33333 14.0017H4.36C4.44667 14.0017 4.53333 13.9683 4.59333 13.9017L11.8733 6.62833L9.37333 4.12833L2.1 11.4017C2.03333 11.4683 2 11.5483 2 11.6417ZM13.8067 4.695C14.0667 4.435 14.0667 4.015 13.8067 3.755L12.2467 2.195C11.9867 1.935 11.5667 1.935 11.3067 2.195L10.0867 3.415L12.5867 5.915L13.8067 4.695Z" fill="#003052"/>
     </svg>`,

  'arrow-down':
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M5.4134 6.19335L8.00006 8.78002L10.5867 6.19335C10.8467 5.93335 11.2667 5.93335 11.5267 6.19335C11.7867 6.45335 11.7867 6.87335 11.5267 7.13335L8.46673 10.1934C8.20673 10.4534 7.78673 10.4534 7.52673 10.1934L4.46673 7.13335C4.20673 6.87335 4.20673 6.45335 4.46673 6.19335C4.72673 5.94002 5.1534 5.93335 5.4134 6.19335Z" fill="#57B5DC"/>' +
    '</svg>',

  'arrow-up': `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="48px" height="48px"><path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6 1.41 1.41z"/>
    </svg>`,

  sortable: '<img src="content/images/sortable.svg">',

  'cross-45':
    '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M18.3 5.71C17.91 5.32 17.28 5.32 16.89 5.71L12 10.59L7.11 5.7C6.72 5.31 6.09 5.31 5.7 5.7C5.31 6.09 5.31 6.72 5.7 7.11L10.59 12L5.7 16.89C5.31 17.28 5.31 17.91 5.7 18.3C6.09 18.69 6.72 18.69 7.11 18.3L12 13.41L16.89 18.3C17.28 18.69 17.91 18.69 18.3 18.3C18.69 17.91 18.69 17.28 18.3 16.89L13.41 12L18.3 7.11C18.68 6.73 18.68 6.09 18.3 5.71Z" fill="#003052"/>' +
    '</svg>',

  'no-music':
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path d="M9.33332 6.40667V4.66667H10.6667C11.4 4.66667 12 4.06667 12 3.33333C12 2.6 11.4 2 10.6667 2H8.66665C8.29999 2 7.99999 2.3 7.99999 2.66667V5.07333L9.33332 6.40667ZM3.41332 2.37333C3.15332 2.11333 2.73332 2.11333 2.47332 2.37333C2.21332 2.63333 2.21332 3.05333 2.47332 3.31333L7.99999 8.84667V9.03333C7.37332 8.67333 6.59999 8.53333 5.77999 8.82C4.88665 9.14 4.19999 9.93333 4.03999 10.8667C3.73332 12.6933 5.27999 14.2533 7.09999 13.9667C8.40665 13.76 9.33332 12.56 9.33332 11.2333V10.18L12.68 13.5267C12.94 13.7867 13.36 13.7867 13.62 13.5267C13.88 13.2667 13.88 12.8467 13.62 12.5867L3.41332 2.37333Z" fill="#EB5757"/>\n' +
    '</svg>',

  'more-horiz': '<img src="content/images/more-horiz.svg">',

  'double-arrow-right': '<img src="content/images/double-arrow-right.svg">',

  delete:
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path\n' +
    'd="M12.2 3.80665C11.94 3.54665 11.52 3.54665 11.26 3.80665L7.99998 7.05998L4.73998 3.79998C4.47998 3.53998 4.05998 3.53998 3.79998 3.79998C3.53998 4.05998 3.53998 4.47998 3.79998 4.73998L7.05998 7.99998L3.79998 11.26C3.53998 11.52 3.53998 11.94 3.79998 12.2C4.05998 12.46 4.47998 12.46 4.73998 12.2L7.99998 8.93998L11.26 12.2C11.52 12.46 11.94 12.46 12.2 12.2C12.46 11.94 12.46 11.52 12.2 11.26L8.93998 7.99998L12.2 4.73998C12.4533 4.48665 12.4533 4.05998 12.2 3.80665Z"\n' +
    'fill="#EB5757"/>\n' +
    '</svg>',

  'trash-bin':
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">' +
    '<path d="M4.00004 12.6667C4.00004 13.4 4.60004 14 5.33337 14H10.6667C11.4 14 12 13.4 12 12.6667V6C12 5.26667 11.4 4.66667 10.6667 4.66667H5.33337C4.60004 4.66667 4.00004 5.26667 4.00004 6V12.6667ZM12 2.66667H10.3334L9.86004 2.19333C9.74004 2.07333 9.56671 2 9.39337 2H6.60671C6.43337 2 6.26004 2.07333 6.14004 2.19333L5.66671 2.66667H4.00004C3.63337 2.66667 3.33337 2.96667 3.33337 3.33333C3.33337 3.7 3.63337 4 4.00004 4H12C12.3667 4 12.6667 3.7 12.6667 3.33333C12.6667 2.96667 12.3667 2.66667 12 2.66667Z" fill="#EB5757"/>' +
    '</svg>',

  'spinner-large': '<img src="content/images/spinner-96.svg">',
  'spinner-small': '<img src="content/images/spinner-24.svg">',

  'info-16':
    '<svg xmlns="http://www.w3.org/2000/svg" id="svg819" version="1.1" viewBox="0 0 16 16" height="16" width="16">\n' +
    '  <defs id="defs823" />\n' +
    '  <path style="fill:none" id="path815" d="M 0,-8 H 24 V 16 H 0 Z" />\n' +
    '  <path style="stroke-width:0.70000005" id="path817" d="M 7.3,4.5 H 8.7 V 5.9 H 7.3 Z m 0,2.8 h 1.4 v 4.2 H 7.3 Z M 8,1 C 4.136,1 1,4.136 1,8 c 0,3.864 3.136,7 7,7 3.864,0 7,-3.136 7,-7 C 15,4.136 11.864,1 8,1 Z M 8,13.6 C 4.913,13.6 2.4,11.087 2.4,8 2.4,4.913 4.913,2.4 8,2.4 c 3.087,0 5.6,2.513 5.6,5.6 0,3.087 -2.513,5.6 -5.6,5.6 z" />\n' +
    '</svg>',

  copy: `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 16 16" width="16" version="1.1" id="svg847">
          <path d="M 0,-8 H 24 V 16 H 0 Z" id="path843" style="fill:none" />
          <path d="M 10.842108,1.0000001 H 3.2631583 C 2.5684212,1.0000001 2,1.5727273 2,2.272727 v 8.909091 H 3.2631583 V 2.272727 H 10.842108 Z M 10.21053,3.545455 H 5.7894751 c -0.6947371,0 -1.2568426,0.572727 -1.2568426,1.272727 l -0.00632,8.90909 C 4.5263125,14.427273 5.0884181,15 5.7831552,15 H 12.736842 C 13.431579,15 14,14.427273 14,13.727272 V 7.363636 Z M 5.7894751,13.727272 v -8.90909 h 3.789475 V 8 h 3.1578959 v 5.727272 z" id="path845" style="stroke-width:0.63396686;fill:#003052" />
      </svg>`,

  'arrow-up-filled': `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" version="1.1" id="svg6">
          <path d="M0 0h24v24H0z" fill="none" id="path2" />
          <path class="content" d="M 1,12.846154 H 7.285714 V 23 h 9.428571 V 12.846154 H 23 L 12,1 Z" id="path4" style="stroke-width:1.63074839" />
       </svg>`,

  height: `<svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24">
            <g><rect fill="none" height="24" width="24"/></g>
            <g><g/><polygon points="13,6.99 16,6.99 12,3 8,6.99 11,6.99 11,17.01 8,17.01 12,21 16,17.01 13,17.01"/></g>
       </svg>`,

  'plus-one': `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path class="content" d="M10 8H8v4H4v2h4v4h2v-4h4v-2h-4V8zm4.5-1.92V7.9l2.5-.5V18h2V5l-4.5 1.08z"/>
      </svg>`,

  'swap-horiz': `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0z" fill="none"/>
        <path class="content" d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z"/>
      </svg>`,

  't-letter-16': `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 4 4">
          <g transform="translate(0,-292.76667)">
            <text style="font-style:normal;font-weight:normal;font-size:4.93888903px;line-height:6.61458302px;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill-opacity:1;stroke:none;stroke-width:0.26458332" x="0" y="0">
              <tspan x="0.55" y="296.35" style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-family:'Sitka Heading';stroke-width:0.26458332">T</tspan></text>
          </g>
      </svg>`,
  'check-circle': `<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 12 12" width="12">
        <path d="M 0,-12 H 24 V 12 H 0 Z" id="path4530" style="fill:none" />
        <path d="M 6.0000001,1 C 3.24,1 1,3.24 1,6 1,8.76 3.24,11 6.0000001,11 8.76,11 11,8.76 11,6 11,3.24 8.76,1 6.0000001,1 Z m -1,7.5 L 2.5,6 3.205,5.295 5.0000001,7.085 8.7950001,3.29 9.4999999,4 Z"
            id="path4532" style="stroke-width:0.5;fill:#3bb83b" />
      </svg>`,
  restart: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z"/>
        <path d="M0 0h24v24H0z" fill="none"/>
      </svg>`,

  error: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
        <path d="M0 0h24v24H0V0z" fill="none"/>
        <path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" fill="#EB5757"/>
        </svg>`,

  calendar: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/>
        <path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/>
       </svg>`,

  download: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
        <path d="M280-280h400v-80H280v80Zm200-120 160-160-56-56-64 62v-166h-80v166l-64-62-56 56 160 160Zm0 320q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
      </svg>`,

  flag: `<svg fill="#000000" width="16px" height="16px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.25 2.979c-5 0-6.333-3-12.666-3-4.084 0-6.584 3.084-6.584 3.084v27.958c0 0.552 0.448 1 1 1s1-0.448 1-1v-12.746c1.055-0.68 2.511-1.296 4.334-1.296 6.333 0 8.166 3 13.166 3s8.5-3 8.5-3v-17s-3.75 3-8.75 3zM28 15.96c-1.13 0.737-3.524 2.019-6.5 2.019-1.966 0-3.308-0.54-5.007-1.223-2.071-0.832-4.419-1.777-8.159-1.777-1.709 0-3.159 0.43-4.334 1.005v-12.108c0.753-0.685 2.394-1.897 4.584-1.897 2.941 0 4.597 0.714 6.35 1.469 1.746 0.752 3.552 1.531 6.316 1.531 2.664 0 5.004-0.737 6.75-1.529v12.509z"></path>
  </svg>`,

  goblet: `
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.23457 10.155C9.23457 10.0268 9.13313 9.94839 9.00915 9.98063C9.00915 9.98063 8.42851 10.1315 7.99997 10.1315C7.57144 10.1315 6.9908 9.98063 6.9908 9.98063C6.86682 9.94839 6.76538 10.0268 6.76538 10.155V10.8304C6.76538 10.9585 6.87022 11.0633 6.99829 11.0633H9.00166C9.12978 11.0633 9.23457 10.9585 9.23457 10.8304V10.155Z" fill="#003052"/>
      <path d="M11.5408 1.46591C11.5408 1.20863 11.3321 1 11.0749 1H4.92514C4.66786 1 4.45923 1.20863 4.45923 1.46591V5.3095C4.45923 6.51256 4.81705 7.60768 5.46676 8.39304C6.12075 9.1836 7.02043 9.61901 8 9.61901C8.97958 9.61901 9.87925 9.18364 10.5332 8.39304C11.1829 7.60768 11.5408 6.51256 11.5408 5.3095V1.46591Z" fill="#003052"/>
      <path d="M4.01593 6.24294C3.37838 6.00569 3.08481 5.45545 3.08481 4.54074V2.45973H3.94673V1.52795H2.61899C2.36166 1.52795 2.15308 1.73654 2.15308 1.99387V4.54069C2.15308 6.09441 2.89729 7.04085 4.26571 7.26192C4.15329 6.9386 4.0691 6.59751 4.01593 6.24294Z" fill="#003052"/>
      <path d="M4.92514 11.762C4.66786 11.762 4.45923 11.9705 4.45923 12.2279V14.534C4.45923 14.7913 4.66786 14.9999 4.92514 14.9999H11.0749C11.3322 14.9999 11.5408 14.7913 11.5408 14.534V12.2279C11.5408 11.9705 11.3322 11.762 11.0749 11.762H4.92514Z" fill="#003052"/>
      <path d="M13.381 1.52795H12.0532V2.45973H12.9151V4.54069C12.9151 5.4554 12.6215 6.00565 11.984 6.24289C11.9308 6.59747 11.8467 6.9386 11.7342 7.26192C13.1026 7.04085 13.8468 6.09441 13.8468 4.54069V1.99387C13.8469 1.73654 13.6383 1.52795 13.381 1.52795Z" fill="#003052"/>
  </svg>`,

  swap: `<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
    <path
      d="M280-120 80-320l200-200 57 56-104 104h607v80H233l104 104-57 56Zm400-320-57-56 104-104H120v-80h607L623-784l57-56 200 200-200 200Z"
    />
  </svg>`,

  'arrow-up-thick': `
  <svg fill="${Colors.Primary}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 60.731 60.731">
  <g><g><polygon points="30.366,0 0.625,29.735 17.998,29.735 18.003,60.731 42.733,60.729 42.733,29.735 60.107,29.735   " /></g></g></svg>
  `,
};
