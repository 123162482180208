import { AuthService } from 'app/services/auth/auth.service';
import { Component } from '@angular/core';

@Component({
  templateUrl: 'reset.request.html',
})
export class ResetRequestComponent {
  error = null;
  errorEmailNotExists = null;
  resetAccount: any = {};
  success = null;
  successMessageParams = null;

  constructor(private readonly authService: AuthService) {}

  requestReset() {
    this.error = null;
    this.errorEmailNotExists = null;
    this.successMessageParams = null;

    this.authService
      .resetPasswordInit(this.resetAccount.email)
      .then((response) => {
        this.success = 'OK';
        this.successMessageParams = { email: response.email };
      })
      .catch((response) => {
        this.success = null;
        if (response.status === 400 && response.error?.error === 'e-mail address not registered') {
          this.errorEmailNotExists = 'ERROR';
        } else {
          this.error = 'ERROR';
        }
      });
  }
}
