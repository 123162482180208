<front-page-carousel></front-page-carousel>

<front-page-banner></front-page-banner>

<section id="events">
  <div class="container events-wrapper">
    <form class="events-search">
      <div class="input-w-icon">
        <input
          [(ngModel)]="searchString"
          (ngModelChange)="onSearchChange()"
          [ngModelOptions]="{standalone: true}"
          focus-me="searchString == ''"
          placeholder="{{'eventsearch.field' | translate}}"
          type="search"
          class="input-w-icon__input input-outline search-input__input event-search-input"
        />

        <sport-filter [(filters)]="filters.sportIds" (filtersChange)="onSelectedSportsChange()"> </sport-filter>
      </div>
    </form>

    <div class="events-tabs">
      <div ngbNav #nav="ngbNav" class="nav-tabs event-search-nav-tabs" [(activeId)]="selectedTabIndex">
        <div [ngbNavItem]="0">
          <a class="live-events-tab-header" ngbNavLink>{{'event.search.live' | translate}}</a>
          <ng-template ngbNavContent>
            <event-list [events]="liveEvents" [noEventsText]="'event.search.noLiveResults' | translate"> </event-list>
          </ng-template>
        </div>
        <div [ngbNavItem]="1">
          <a ngbNavLink>{{'event.search.future' | translate}}</a>
          <ng-template ngbNavContent>
            <event-list
              [events]="futureEvents"
              [loader]="futureLoader"
              (loadMoreData)="loadMoreFutureEvents()"
              [noEventsText]="'event.search.noFutureResults' | translate"
            >
            </event-list>
          </ng-template>
        </div>
        <div [ngbNavItem]="2">
          <a ngbNavLink>{{'event.search.past' | translate}}</a>
          <ng-template ngbNavContent>
            <event-list
              [events]="pastEvents"
              [loader]="pastLoader"
              (loadMoreData)="loadMorePastEvents()"
              [noEventsText]="'event.search.noPastResults' | translate"
            >
            </event-list>
          </ng-template>
        </div>
      </div>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>

  <scroll-up-button [scrollTo]="scrollUpTarget" [visibleAfter]="showScrollUpAfter"></scroll-up-button>
</section>
