import { Component } from '@angular/core';

@Component({
  selector: 'kisanet-app',
  template: `
    <app-reload-toast></app-reload-toast>
    <navbar></navbar>
    <page-ribbon></page-ribbon>
    <div id="body">
      <div ui-view="content" id="ui-view-content">
        <!-- Angular views -->
        <div style="position: absolute; top: 50%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%)">
          <img src="/content/images/spinner-96.svg" />
        </div>
      </div>
      <app-footer></app-footer>
      <tawk-chat-widget></tawk-chat-widget>
      <cookie-window></cookie-window>
      <global-toasts></global-toasts>
    </div>
  `,
})
export class KisanetAppComponent {}
