import { HttpParams } from '@angular/common/http';
import { convertDateTimeToServer, isValidDate } from 'app/components/util/date-util.service';
import { CustomHttpParamEncoder } from 'app/utils/custom-http-param-encoder';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

const ASC = 1;
const DESC = -1;

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends Array<infer E> ? DeepPartial<E>[] : DeepPartial<T[P]>;
    }
  : T;

export type Comparator<T> = (o1: T, o2: T) => number;

function sortByAttr(attr, dir) {
  return function (o1, o2) {
    if (o1[attr] < o2[attr]) return -1 * dir;
    if (o1[attr] > o2[attr]) return 1 * dir;
    return 0;
  };
}

function sortByAttrNullsLast(attr, dir) {
  return function (o1, o2) {
    const v1 = o1[attr] ?? null;
    const v2 = o2[attr] ?? null;
    if (v1 === null && v2 === null) return 0;
    if (v1 === null) return 1 * dir;
    if (v2 === null) return -1 * dir;
    if (v1 < v2) return -1 * dir;
    if (v1 > v2) return 1 * dir;
    return 0;
  };
}

export function byValueComparator<T>(getValue: (x: T) => any, dir: SortDirection) {
  const dirMultiplier = dir === SortDirection.ASC ? 1 : -1;
  return function (o1: T, o2: T) {
    const v1 = getValue(o1);
    const v2 = getValue(o2);
    if (v1 < v2) return -1 * dirMultiplier;
    if (v1 > v2) return 1 * dirMultiplier;
    return 0;
  };
}

export function reversed<T>(comparator: Comparator<T>): Comparator<T> {
  return (o1, o2) => -1 * comparator(o1, o2);
}

export function combineComparators<T>(...comparators: Comparator<T>[]): Comparator<T> {
  return function (o1, o2) {
    for (let i = 0; i < comparators.length; i++) {
      const result = comparators[i](o1, o2);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  };
}

export const attrComparatorAscNullsLast = <T>(attr: keyof T) => {
  return sortByAttrNullsLast(attr, ASC);
};

export const sortByAttrAsc = <T>(attr: keyof T) => {
  return sortByAttr(attr, ASC);
};

export const sortByAttrDesc = (attr) => {
  return sortByAttr(attr, DESC);
};

export const roundToOneDecimal = (value) => {
  return Math.round(value * 10) / 10;
};

export const roundToThreeDecimals = (value) => {
  return Math.round(value * 1000) / 1000;
};

export function byPropValue(propName, propValue) {
  return function (obj) {
    return obj[propName] === propValue;
  };
}

export function setIfNotEmpty(params: HttpParams, paramName: string, value: any) {
  if (value !== undefined && value !== null) {
    return params.set(paramName, value);
  }
  return params;
}

export function removeByAttr(items, itemToRemove, attrName) {
  const idx = items.findIndex(function (item) {
    return item[attrName] === itemToRemove[attrName];
  });
  if (idx >= 0) {
    items.splice(idx, 1);
  }
}

export function toHttpParams(params: object, useIsoDateTimeFormat: boolean = false): HttpParams {
  return Object.keys(params)
    .filter(
      (propName) => params.hasOwnProperty(propName) && params[propName] !== undefined && params[propName] !== null
    )
    .reduce((httpParams, propName) => {
      let updated = httpParams;
      toHttpParamValue(params[propName], useIsoDateTimeFormat).forEach((value) => {
        updated = updated.append(propName, value);
      });
      return updated;
    }, newHttpParams());
}

export function toHttpParamValue(value: any, useIsoDateTimeFormat: boolean = false): string[] {
  if (value instanceof Array) {
    return value.map((item) => toHttpParamValue(item, useIsoDateTimeFormat)).flatMap((item) => item);
  }
  if (isValidDate(value)) {
    return [convertDateTimeToServer(value, useIsoDateTimeFormat)];
  }
  return [value + ''];
}

export function newHttpParams() {
  return new HttpParams({ encoder: new CustomHttpParamEncoder() });
}

type SortableItem<T> = { item: T; sortValue: any };

export function sortByExtractedValue<T>(items: T[], extractSortValue: (v: T) => any, ascending: boolean): T[] {
  const sortableItems: SortableItem<T>[] = items.map(function (item) {
    return { item: item, sortValue: extractSortValue(item) };
  });
  const comparator = ascending ? sortByAttrAsc<SortableItem<T>>('sortValue') : sortByAttrDesc('sortValue');
  sortableItems.sort(comparator);
  return sortableItems.map(function (sortableItem) {
    return sortableItem.item;
  });
}

export function shuffle<T>(elements: T[]): T[] {
  for (let i = 0; i < elements.length; i++) {
    const p = getRandomInt(elements.length);
    const tmp = elements[i];
    elements[i] = elements[p];
    elements[p] = tmp;
  }
  return elements;
}

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function distinct(value, index, self) {
  return self.indexOf(value) === index;
}

export function fieldComparator(fieldName) {
  return function (o1, o2) {
    if (o1[fieldName] < o2[fieldName]) return -1;
    if (o1[fieldName] > o2[fieldName]) return 1;
    return 0;
  };
}

export function eqSet(s1: Set<any>, s2: Set<any>): boolean {
  return s1.size === s2.size && Array.from(s1).every((x) => s2.has(x));
}

export function sortByMultipleValues<T>(elements: T[], comparators: Comparator<T>[]) {
  elements.sort((e1, e2) => {
    let compIndex = 0;
    while (compIndex < comparators.length) {
      const comparator = comparators[compIndex];
      compIndex++;
      const result = comparator(e1, e2);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  });
}

export function sumByKey<T extends PropertyKey>(list: { [K in T]?: number }[], key: T) {
  return list.reduce((sum, item) => sum + item[key] ?? 0, 0);
}

type TaggedData<T> = { data: T; tag: string };

@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor(private translateService: TranslateService) {}

  sortByLocalizedStrings<T>(
    array: T[],
    toLocalizationKeyMapper: (e: T) => string,
    otherComparators: Comparator<T>[] = []
  ): T[] {
    const elementsWithLocalizedStrings: TaggedData<T>[] = array.map((value) => {
      const locKey = toLocalizationKeyMapper(value);
      return { data: value, tag: this.translateService.instant(locKey) };
    });
    const allComparators: Comparator<TaggedData<T>>[] = [
      (o1: TaggedData<T>, o2: TaggedData<T>) => o1.tag.localeCompare(o2.tag),
      ...otherComparators.map((comparator) => (o1: TaggedData<T>, o2: TaggedData<T>) => comparator(o1.data, o2.data)),
    ];
    sortByMultipleValues(elementsWithLocalizedStrings, allComparators);
    return elementsWithLocalizedStrings.map((container) => container.data);
  }
}

export class Debounce {
  private scheduledExecution: any;
  private requestRunning = false;
  private requestedFn: () => Promise<any> = null;

  constructor(private delayMillis: number = 500) {}

  debounce(fn: () => Promise<any>) {
    this.requestedFn = fn;
    if (!this.scheduledExecution && !this.requestRunning) {
      this.scheduledExecution = setTimeout(() => {
        this.scheduledExecution = null;
        this.doExecute();
      }, this.delayMillis);
    }
  }

  doExecute() {
    this.requestRunning = true;
    const delayedFn = this.requestedFn;
    this.requestedFn = null;
    delayedFn().finally(() => {
      this.requestRunning = false;
      if (this.requestedFn) {
        this.doExecute();
      }
    });
  }
}
