import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export const SKIP_CACHE_BUSTER_INTERCEPTOR = '_$SKIP_CACHE_BUSTER_INTERCEPTOR$_';

@Injectable()
export class CacheBusterInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.params.has('cacheBuster')) {
      if (req.params.has(SKIP_CACHE_BUSTER_INTERCEPTOR)) {
        const clonedRequest = req.clone({ params: req.params.delete(SKIP_CACHE_BUSTER_INTERCEPTOR) });
        return next.handle(clonedRequest);
      }
      const clonedRequest = req.clone({ params: req.params.set('cacheBuster', new Date().getTime()) });
      return next.handle(clonedRequest);
    }
    return next.handle(req);
  }
}
