import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { PrincipalService } from 'app/services/auth/principal.service';
import { Subscription } from 'rxjs';

// legacyComponents.directive('hasAuthority', hasAuthority);

hasAuthority.$inject = ['Principal'];

function hasAuthority(Principal) {
  var directive = {
    restrict: 'A',
    link: linkFunc,
  };

  return directive;

  function linkFunc(scope, element, attrs) {
    var authority = attrs.hasAuthority.replace(/\s+/g, '');

    var setVisible = function () {
        element.removeClass('d-none');
      },
      setHidden = function () {
        element.addClass('d-none');
      },
      defineVisibility = function (reset) {
        if (reset) {
          setVisible();
        }

        Principal.hasAuthority(authority).then(function (result) {
          if (result) {
            setVisible();
          } else {
            setHidden();
          }
        });
      };

    if (authority.length > 0) {
      defineVisibility(true);

      scope.$watch(
        function () {
          return Principal.isAuthenticated();
        },
        function () {
          defineVisibility(true);
        }
      );
    }
  }
}

@Directive({
  selector: '[hasAuthority], [hasAnyAuthority]',
})
export class HasAuthorityDirective implements OnInit, OnDestroy {
  @Input('hasAuthority') requiredAuthority: string;
  @Input('hasAnyAuthority') requiredAuthorities: string;
  subscription: Subscription;

  constructor(private elementRef: ElementRef, private principalService: PrincipalService) {}

  ngOnInit() {
    const self = this;
    this.subscription = this.principalService.getCurrentIdentity().subscribe({
      next() {
        self.updateVisibility();
      },
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private updateVisibility() {
    const requiredAuthorities = this.requiredAuthorities
      ? this.requiredAuthorities.split(',').map((a) => a.trim())
      : [this.requiredAuthority];

    if (this.principalService.hasAnyAuthority(requiredAuthorities)) {
      this.elementRef.nativeElement.style.display = 'block';
    } else {
      this.elementRef.nativeElement.style.display = 'none';
    }
  }
}
