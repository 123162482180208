import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppReloadService } from 'app/services/app-reload.service';

@Injectable()
export class AppUpdateInterceptor implements HttpInterceptor {
  private appHash: string = null;

  constructor(private reloadService: AppReloadService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: (response) => {
          if (response instanceof HttpResponse) {
            const newHash = response.headers.get('X-App-Hash');
            if (!this.appHash && newHash) {
              console.log('AppHash not set. Setting to: ' + newHash);
              this.appHash = newHash;
            }
            if (newHash && newHash !== this.appHash) {
              console.log('New AppHash does not match the old. ' + this.appHash + ', ' + newHash);
              this.appHash = newHash;
              this.reloadService.showReloadPrompt();
            }
            return response;
          }
        },
      })
    );
  }

  getCurrentAppHash() {
    return this.appHash;
  }
}
