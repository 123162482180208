import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { JhiLanguageService } from 'app/components/language/language.service';

@Component({
  selector: 'jhi-language',
  templateUrl: './language.html',
})
export class JhiLanguageComponent {
  current: string;
  languages: string[] = [];

  constructor(private jhiLanguageService: JhiLanguageService, private translateService: TranslateService) {
    jhiLanguageService.getCurrent().then((lang) => {
      this.current = lang;
    });
    this.languages = null;

    this.jhiLanguageService.getAll().then((languages) => {
      this.languages = languages;
    });
  }

  changeLanguage(languageKey) {
    this.translateService.use(languageKey);
    this.current = languageKey;
  }
}
