import { convertDateTimeFromServer, setDay } from 'app/components/util/date-util.service';
import { sortByAttrAsc } from 'app/services/utils.service';
import { StartListInfo } from 'app/entities/start-list/start-list-info.types';

export function transformStartListFromServer(data) {
  if (data) {
    const startList = typeof data === 'string' ? JSON.parse(data) : data;
    if (startList.blocks) {
      startList.blocks.forEach(function (block) {
        transformStartListBlockFromServer(block);
      });
    }
    if (startList.spec && startList.spec.blockSpecs) {
      startList.spec.blockSpecs.forEach(function (blockSpec) {
        transformStartListBlockSpecFromServer(blockSpec);
      });
    }
    return startList;
  }
  return data;
}

export function transformStartListBlockFromServerJson(data) {
  if (data) {
    const block = typeof data === 'string' ? JSON.parse(data) : data;
    return transformStartListBlockFromServer(block);
  }
  return data;
}

function transformStartListBlockSpecFromServer(blockSpec) {
  if (blockSpec) {
    blockSpec.startTime = convertDateTimeFromServer(blockSpec.startTime);
  }
  return blockSpec;
}

function transformStartListBlockFromServer(block) {
  if (block) {
    block.startTime = convertDateTimeFromServer(block.startTime);
    block.endTime = convertDateTimeFromServer(block.endTime);
    block.order = block.blockOrder;
    if (block.entries) {
      block.entries.forEach(function (entry) {
        entry.scheduledTime = convertDateTimeFromServer(entry.scheduledTime);
      });
      block.entries.sort(sortByAttrAsc('orderNumber'));
    }
  }
  return block;
}

export function transformStartListSpecFromServer(data) {
  if (data) {
    const spec = typeof data === 'string' ? JSON.parse(data) : data;
    if (spec.blockSpecs) {
      spec.blockSpecs.forEach(function (blockSpec) {
        transformStartListBlockSpecFromServer(blockSpec);
      });
    }
    return spec;
  }
  return data;
}

export function setStartListDay(startList, day) {
  startList.blocks.forEach(function (block) {
    block.entries.forEach(function (entry) {
      setDay(entry.scheduledTime, day);
    });
    setDay(block.startTime, day);
    setDay(block.endTime, day);
  });
}

// export const transformStartListFromServer = data => {
//   if (data) {
//     data = JSON.parse(data);
//     if (data.blocks) {
//       data.blocks.forEach(function (block) {
//         block.startTime = convertDateTimeFromServer(block.startTime);
//         block.endTime = convertDateTimeFromServer(block.endTime);
//         if (block.entries) {
//           block.entries.forEach(function (entry) {
//             entry.scheduledTime = convertDateTimeFromServer(entry.scheduledTime);
//           });
//           block.entries.sort(sortByAttrAsc('orderNumber'));
//         }
//       });
//     }
//   }
//   return data;
// }
