import { z } from 'zod';
import { convertDateTimeToIso } from 'app/components/util/date-util.service';

export enum OrganizationType {
  PROVIDER = 'PROVIDER',
  FEDERATION = 'FEDERATION',
  CLUB = 'CLUB',
  OTHER = 'OTHER',
}

export type Organization = z.infer<typeof OrganizationSchema>;

export const OrganizationSchema = z.object({
  id: z.number(),
  type: z.nativeEnum(OrganizationType),
  name: z.string(),
  extOrgUnitId: z.number().optional().nullable(),
  shortName: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  phoneNumber: z.string().optional().nullable(),
  regionId: z.number().optional().nullable(),
  regionName: z.string().optional().nullable(),
  parentOrgId: z.number().optional().nullable(),
  lastSyncTime: z.string().pipe(z.coerce.date()).optional().nullable(),
});

export const OrganizationSchemaToServer = OrganizationSchema.extend({
  lastSyncTime: z.date().transform(convertDateTimeToIso).optional().nullable(),
});
