import { Component } from '@angular/core';
import { ToastService } from 'app/shared/toast.service';

@Component({
  selector: 'global-toasts',
  template: `
    <div class="global-toasts">
      <ngb-toast
        *ngFor="let toast of toastService.toasts"
        header="unused"
        [autohide]="toast.delay > 0"
        [delay]="toast.delay || 5000"
        (hidden)="toastService.remove(toast)"
        class="toast"
        [ngClass]="toast.type"
      >
        <ng-template ngbToastHeader>
          <div class="custom-toast-header" [innerHTML]="toast.body | translate: toast.params">
            body
          </div>
        </ng-template>
      </ngb-toast>
    </div>
  `,
  styles: [
    `
      .custom-toast-header {
        padding-right: 10px;
        overflow: hidden;
      }
    `,
  ],
})
export class GlobalToastsComponent {
  constructor(public toastService: ToastService) {}
}
