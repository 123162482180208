import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'event-label',
  template: `<span class="event-item__virtual-tag" [ngClass]="'event-item__virtual-tag_' + type">{{
    labelText
  }}</span>`,
})
export class EventLabelComponent implements OnInit {
  @Input() type;
  labelText;

  ngOnInit() {
    this.type = this.type || 'VIRTUAL';
    this.labelText = this.type;
  }
}
