import { AuthService } from 'app/services/auth/auth.service';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { NavbarComponent } from 'app/layouts/navbar/navbar.component';

export const appState: Ng2StateDeclaration = {
  name: 'app',
  abstract: true,
  views: {
    'navbar@': {
      component: NavbarComponent,
    },
  },
  resolve: [
    {
      token: 'authorize',
      deps: [AuthService],
      resolveFn: (authService: AuthService) => authService.authorize(),
    },
    {
      token: '$pageTitle',
      resolveFn: () => 'global.title',
    },
  ],
};
