import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ProfileService {
  private dataPromise: Promise<any>;

  constructor(private $http: HttpClient) {}

  getProfileInfo() {
    if (this.dataPromise === undefined) {
      this.dataPromise = this.$http
        .get('api/profile-info')
        .toPromise()
        .then((result: any) => {
          if (result.activeProfiles) {
            const response: any = {};
            response.activeProfiles = result.activeProfiles;
            response.ribbonEnv = result.ribbonEnv;
            response.inProduction = (result.activeProfiles?.indexOf('prod') || -1) !== -1;
            response.swaggerEnabled = (result.activeProfiles?.indexOf('swagger') || -1) !== -1;
            return response;
          }
        });
    }
    return this.dataPromise;
  }
}
