<section class="container content-section auth-page">
  <header class="content-section__header">
    <h1 class="content-section__heading auth-page__heading" [translate]="'reset.finish.title'">
      Reset your password
    </h1>
  </header>

  <main class="content-section__main auth-page__main">
    <div class="alert alert-danger" [translate]="'reset.finish.messages.keymissing'" *ngIf="keyMissing">
      <strong>The password reset key is missing.</strong>
    </div>

    <div class="alert alert-warning" *ngIf="!(success || keyMissing)">
      <span [translate]="'reset.finish.messages.info'">
        Choose a new password
      </span>
    </div>

    <div class="alert alert-danger" *ngIf="error">
      <span [translate]="'reset.finish.messages.error'">
        Your password couldn't be reset. Remember a password request is only valid for 24 hours.
      </span>
    </div>

    <div class="alert alert-success" *ngIf="success">
      <span [innerHtml]="'reset.finish.messages.success' | translate">
        <strong>Your password has been reset.</strong> Please
        <a class="alert-link" href="" (click)="login()">sign in</a>.
      </span>
    </div>

    <div class="alert alert-danger" *ngIf="doNotMatch" [translate]="'global.messages.error.dontmatch'">
      The password and its confirmation do not match!
    </div>

    <div *ngIf="!keyMissing">
      <form
        #form="ngForm"
        *ngIf="!success"
        name="form"
        role="form"
        novalidate
        (ngSubmit)="finishReset()"
        show-validation
      >
        <div class="form-group">
          <label for="password" [translate]="'global.form.newpassword'">
            New password
          </label>

          <input
            auto-complete="off"
            type="password"
            class="form-control"
            id="password"
            name="password"
            placeholder="{{'global.form.newpassword.placeholder' | translate}}"
            [(ngModel)]="resetAccount.password"
            [minlength]="4"
            [maxlength]="50"
            [required]="true"
          />

          <div *ngIf="form.controls.password?.dirty && form.controls.password?.invalid">
            <div
              class="invalid-feedback"
              *ngIf="form.controls.password.errors?.required"
              [translate]="'global.messages.validate.newpassword.required'"
            >
              Your password is required.
            </div>

            <div
              class="invalid-feedback"
              *ngIf="form.controls.password.errors?.minlength"
              [translate]="'global.messages.validate.newpassword.minlength'"
            >
              Your password is required to be at least 4 characters.
            </div>

            <div
              class="invalid-feedback"
              *ngIf="form.controls.password.errors?.maxlength"
              [translate]="'global.messages.validate.newpassword.maxlength'"
            >
              Your password cannot be longer than 50 characters.
            </div>
          </div>

          <!-- <password-strength-bar password-to-check="resetAccount.password"></password-strength-bar> -->
        </div>

        <div class="form-group">
          <label class="control-label" for="confirmPassword" [translate]="'global.form.confirmpassword'">
            New password confirmation
          </label>

          <input
            type="password"
            auto-complete="off"
            class="form-control"
            id="confirmPassword"
            name="confirmPassword"
            placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
            [(ngModel)]="confirmPassword"
            [minlength]="4"
            [maxlength]="50"
            [required]="true"
          />

          <div *ngIf="form.controls.confirmPassword?.dirty && form.controls.confirmPassword?.invalid">
            <div
              class="invalid-feedback"
              *ngIf="form.controls.confirmPassword.errors?.required"
              [translate]="'global.messages.validate.confirmpassword.required'"
            >
              Your password confirmation is required.
            </div>

            <div
              class="invalid-feedback"
              *ngIf="form.controls.confirmPassword.errors?.minlength"
              [translate]="'global.messages.validate.confirmpassword.minlength'"
            >
              Your password confirmation is required to be at least 4 characters.
            </div>

            <div
              class="invalid-feedback"
              *ngIf="form.controls.confirmPassword.errors?.maxlength"
              [translate]="'global.messages.validate.confirmpassword.maxlength'"
            >
              Your password confirmation cannot be longer than 50 characters.
            </div>
          </div>
        </div>

        <div class="auth-page__buttons">
          <button
            type="submit"
            [disabled]="form.invalid"
            class="btn btn-blue btn-block"
            [translate]="'reset.finish.form.button'"
          >
            Reset Password
          </button>
        </div>
      </form>
    </div>
  </main>
</section>
