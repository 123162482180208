import { Injectable } from '@angular/core';

export interface IContextService {
  set(key: string, value: any);
  get(key: string): any;
  clear(key: string);
}

@Injectable({ providedIn: 'root' })
export class ContextService implements IContextService {
  private values = {};

  clear(key: string) {
    delete this.values[key];
  }

  get(key: string): any {
    return this.values[key];
  }

  set(key: string, value: any) {
    this.values[key] = value;
  }
}
