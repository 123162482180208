import { NgModule } from '@angular/core';
import { LoginDialogComponent } from 'app/components/login/login-dialog.component';
import { FormsModule } from '@angular/forms';
import { LoginServiceNg2 } from 'app/components/login/login.service';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule, UIRouterModule],
  declarations: [LoginDialogComponent],
  providers: [LoginServiceNg2],
  exports: [],
})
export class LoginModule {}
