import { PrincipalService } from 'app/services/auth/principal.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoginServiceNg2 } from 'app/components/login/login.service';
import { AuthService } from 'app/services/auth/auth.service';
import { StateService } from '@uirouter/core';
import { ProfileService } from 'app/services/profiles/profile.service';

@Component({
  selector: 'navbar',
  templateUrl: 'navbar.html',
})
export class NavbarComponent implements OnInit, OnDestroy {
  isNavbarCollapsed = true;
  subscription;
  inProduction: boolean = false;
  swaggerEnabled: boolean = false;
  principal: any;

  constructor(
    private principalService: PrincipalService,
    private loginService: LoginServiceNg2,
    private auth: AuthService,
    private stateService: StateService,
    private profileService: ProfileService
  ) {}

  ngOnInit() {
    const self = this;
    this.subscription = this.principalService.getCurrentIdentity().subscribe({
      next(principal) {
        self.principal = principal;
      },
    });
    this.profileService.getProfileInfo().then((response) => {
      this.inProduction = response.inProduction;
      this.swaggerEnabled = response.swaggerEnabled;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  login() {
    this.collapseNavbar();
    this.loginService.open();
  }

  logout() {
    this.collapseNavbar();
    this.auth.logout();
    this.principal = null;
    this.stateService.go('app.home');
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  collapseNavbar() {
    this.isNavbarCollapsed = true;
  }

  isAuthenticated() {
    return this.principalService.isAuthenticated();
  }
}
