import { DEFAULT_PAGE_SIZE } from './page-request';
import { IPage } from 'app/model/page';

export abstract class AbstractPaginatedLoader<T, SR, SD> {
  readonly objects: T[];
  readonly pageSize: number;
  protected onSuccess: any;
  protected onError: any;
  protected searchData: SD | null;
  protected _totalCount: number;
  protected _loading: boolean = false;
  protected _loadedPage: number = -1;
  protected loadedCount: number;
  protected sort: string[];
  protected stateId: number = 0;

  protected constructor(objects: T[], pageSize: number = DEFAULT_PAGE_SIZE) {
    this.objects = objects;
    this.pageSize = pageSize;
  }

  get totalCount() {
    return this._totalCount;
  }

  get loadedPage() {
    return this._loadedPage;
  }

  get loading() {
    return this._loading;
  }

  load(searchData: SD, sort?: string[]): Promise<void> {
    this.resetState();
    this.searchData = searchData;
    this.sort = sort ? sort.slice() : [];
    return this.loadNextPage();
  }

  protected resetState(): void {
    this.objects.splice(0);
    this.loadedCount = 0;
    this._totalCount = -1;
    this._loadedPage = -1;
    this._loading = false;
    this.stateId++;
    this.searchData = null;
  }

  loadNextPage(): Promise<void> {
    if (this.loading || (this._totalCount >= 0 && this.loadedCount >= this._totalCount)) {
      return Promise.resolve();
    }
    this._loading = true;
    const requestStateId = this.stateId;
    return this.doLoad(this.createSearchRequest(this._loadedPage + 1, this.pageSize, this.sort))
      .then((page) => {
        if (this.stateId !== requestStateId) {
          return;
        }
        page.content.forEach((e) => {
          this.objects.push(e);
          this.loadedCount++;
        });
        this._loadedPage++;
        this._totalCount = page.totalCount;
      })
      .finally(() => (this._loading = false));
  }

  loadCustomPage(pageSize: number, pageNumberAfterLoad: number): Promise<void> {
    if (this.loading || (this._totalCount >= 0 && this.loadedCount >= this._totalCount)) {
      return Promise.resolve();
    }
    this._loading = true;
    const requestStateId = this.stateId;
    return this.doLoad(this.createSearchRequest(0, pageSize, this.sort))
      .then((page) => {
        if (this.stateId !== requestStateId) {
          return;
        }
        page.content.forEach((e) => {
          this.objects.push(e);
          this.loadedCount++;
        });
        this._loadedPage = pageNumberAfterLoad;
        this._totalCount = page.totalCount;
      })
      .finally(() => (this._loading = false));
  }

  protected abstract createSearchRequest(pageToLoad: number, pageSize: number, sort: string[]): SR;

  protected abstract doLoad(request: SR): Promise<IPage<T>>;
}
