import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginServiceNg2 } from 'app/components/login/login.service';
import { AuthService } from 'app/services/auth/auth.service';
import { StateService } from '@uirouter/core';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(
    private loginService: LoginServiceNg2,
    private authService: AuthService,
    private stateService: StateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: (error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 401 && error.url.indexOf('/api/account') < 0) {
              const targetStateName = this.stateService.transition?.targetState()?.name();
              if (targetStateName && targetStateName !== 'accessdenied') {
                this.authService.storePreviousState(
                  targetStateName,
                  this.stateService.transition.targetState().params()
                );
              }
              this.loginService.open();
            }
          }
        },
      })
    );
  }
}
