import { AuthService } from 'app/services/auth/auth.service';
import { StateService } from '@uirouter/core';
import { Component, OnInit } from '@angular/core';
import { LoginServiceNg2 } from 'app/components/login/login.service';

@Component({
  templateUrl: 'reset.finish.html',
})
export class ResetFinishComponent implements OnInit {
  keyMissing: boolean;
  confirmPassword = null;
  doNotMatch: string | null = null;
  error: string | null = null;
  resetAccount: any = {};
  success: string | null = null;

  constructor(
    private readonly authService: AuthService,
    private readonly stateService: StateService,
    private readonly loginService: LoginServiceNg2
  ) {}

  ngOnInit() {
    this.keyMissing = this.stateService.params.key === undefined;
  }

  finishReset() {
    this.doNotMatch = null;
    this.error = null;
    if (this.resetAccount.password !== this.confirmPassword) {
      this.doNotMatch = 'ERROR';
    } else {
      const key = this.stateService.params.key;
      this.authService
        .resetPasswordFinish({ key, newPassword: this.resetAccount.password })
        .then(() => {
          this.success = 'OK';
        })
        .catch(() => {
          this.success = null;
          this.error = 'ERROR';
        });
    }
  }

  login() {
    this.loginService.open();
  }
}
