import { NgModule } from '@angular/core';
import { FrontPageCarouselComponent } from 'app/eventsearch/front-page-carousel.component';
import { EventSearchComponent } from 'app/eventsearch/eventsearch.component';
import { EventListComponent } from 'app/eventsearch/event-list.component';
import { SportFilterComponent } from 'app/eventsearch/sport-filter.component';
import { Ng2StateDeclaration } from '@uirouter/angular';
import { CoreModule } from 'app/core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { FrontPageBannerComponent } from 'app/eventsearch/front-page-banner.component';
import { BannersModule } from 'app/features/banners/banners.module';
import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { EventsModule } from 'app/features/events/events.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { JhiLanguageService } from 'app/components/language/language.service';
import { ProfileService } from 'app/services/profiles/profile.service';
import { SharedModule } from 'app/shared/shared.module';

export const homeState: Ng2StateDeclaration = {
  name: 'app.home',
  url: '/',
  views: {
    'content@': {
      component: EventSearchComponent,
    },
  },
  onEnter: () => {
    console.log('Entering home state');
  },
};

@NgModule({
  imports: [
    // UIRouterModule.forChild({ states: [homeState] }),
    CoreModule,
    SharedModule,
    TranslateModule,
    BannersModule,
    NgbCarouselModule,
    EventsModule,
    InfiniteScrollModule,
  ],
  declarations: [
    FrontPageCarouselComponent,
    FrontPageBannerComponent,
    EventSearchComponent,
    EventListComponent,
    SportFilterComponent,
  ],
  providers: [JhiLanguageService, ProfileService],
  exports: [],
})
export class HomeModule {}
