export const DEFAULT_PAGE_SIZE: number = 10;

export interface IPageRequest {
  page: number;
  size: number;
  sort: string[];
}

export class PageRequest implements IPageRequest {
  readonly page: number;
  readonly size: number;
  readonly sort: string[];

  constructor(page: number, size: number, sort: string[]) {
    this.page = page;
    this.size = size;
    this.sort = sort;
  }
}
