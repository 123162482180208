import { ResetFinishComponent } from 'app/account/reset/finish/reset.finish.component';
import { Ng2StateDeclaration } from '@uirouter/angular';

export const resetFinishState: Ng2StateDeclaration = {
  name: 'app.account.finishReset',
  url: '/reset/finish?key',
  data: {
    authorities: [],
  },
  resolve: [
    {
      token: '$pageTitle',
      resolveFn: () => 'event.home.title',
    },
  ],
  views: {
    'content@': {
      component: ResetFinishComponent,
    },
  },
};
