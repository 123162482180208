import { Component } from '@angular/core';

@Component({
  selector: 'cookie-window',
  templateUrl: 'cookie-window.html',
})
export class CookieWindowComponent {
  showCookieWindow: boolean = false;

  constructor() {
    this.showCookieWindow = !localStorage?.getItem('acceptedCookies');
  }

  onCookiesAccepted() {
    localStorage?.setItem('acceptedCookies', 'true');
    this.showCookieWindow = false;
  }
}
