import { Directive, ElementRef } from '@angular/core';
import { AbstractAuthenticationDirective } from 'app/services/auth/abstract-authentication-directive';
import { PrincipalService } from 'app/services/auth/principal.service';

@Directive({
  selector: '[isAuthenticated]',
})
export class IsAuthenticatedDirective extends AbstractAuthenticationDirective {
  constructor(element: ElementRef, principalService: PrincipalService) {
    super(element, principalService);
  }

  elementVisible() {
    return this.principalService.isAuthenticated();
  }
}
