<div class="login-modal">
  <div class="modal-header">
    <h4 class="modal-title" [translate]="'login.title'">Sign in</h4>
    <button (click)="cancel()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <!--    <div class="modal-descrption">-->
    <div
      class="alert alert-danger"
      *ngIf="authenticationError"
      [innerHTML]="'login.messages.error.authentication' | translate"
    >
      Failed to sign in!
    </div>
    <!--    </div>-->

    <form (submit)="login($event)" class="modal-from login-form">
      <div class="form-group">
        <input
          #usernameInput
          id="username"
          [(ngModel)]="username"
          placeholder="{{'global.form.username.placeholder' | translate}}"
          name="username"
          type="text"
          class="form-control"
        />
      </div>

      <div class="form-group">
        <input
          type="password"
          class="form-control"
          id="password"
          name="password"
          [(ngModel)]="password"
          placeholder="{{'login.form.password.placeholder' | translate}}"
        />
      </div>

      <div class="form-group login-form__support-action">
        <!-- <div class="custom-checkbox">
                    <input
                        class="visually-hidden"
                        type="checkbox"
                        value="true"
                        ng-model="vm.rememberMe"
                        id="rememberMe"
                        tabindex="-1"
                    />
                    <label for="rememberMe">
                        {{ 'login.form.rememberme' | translate }}
                    </label>
                </div> -->

        <a class="link" href="#" (click)="requestResetPassword($event)" [translate]="'login.password.forgot'">
          Did you forget your password?
        </a>
      </div>

      <div class="modal-buttons">
        <button
          type="submit"
          class="btn btn-info btn-block text-white text-uppercase"
          [translate]="'login.form.button'"
        >
          Sign in
        </button>
      </div>
    </form>
  </div>
</div>
